<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
        <div class="row-fluid">
          <Welcome />
          <HorizontalToolBar />
        </div>
        <div class="row-fluid">
          <div class="card-deck">
            <div
              class="col-xs-12 col-md-12 col-lg-4 col-xl-3 mb-4 p-0"
              v-for="post in posts"
              :key="post.id"
            >
              <div class="card">
                <img
                  class="card-img-top img-fluid"
                  :src="post.fimg_url"
                  alt="Card image cap"
                />
                <div class="card-body">
                  <h5 class="card-title">{{ post.title.rendered }}</h5>
                  <p class="card-text" v-html="post.excerpt.rendered"></p>
                  <Button
                    :location="post.link"
                    name="Read More"
                    icon="fas fa-level-up-alt"
                  />
                  <p class="card-text">
                    <small class="text-muted"
                      >Last updated {{ post.modified }}</small
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <a @click="$router.go(-1)" class="btn btn-primary"
            ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Welcome from "../components/Welcome";
import HorizontalToolBar from "../components/HorizontalToolBar";
import Button from "../components/Button.vue";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "AmerusJournal",
  components: {
    HorizontalToolBar,
    Welcome,
    Button,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
    posts() {
      return this.$store.getters.getAmerusJounral;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
</style>
