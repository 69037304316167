<template>
  <div>
    <h1 class="text-light">Thank You For Your Order!</h1>
    <router-link class="btn btn-primary" to="/dashboard">Dashboard</router-link>
  </div>
</template>

<script>
export default {
  name: "Thanks",
  methods: {
    createOrder() {
      this.$store.dispatch("createOrder", {
        status: "completed",
      });
    },
  },
  mounted() {
    this.createOrder();
  },
};
</script>