<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="row-fluid">
            <h1 class="text-light">Check Out</h1>
            <div class="row">
              <div class="col-md-4 order-md-2 mb-4">
                <h4
                  class="d-flex justify-content-between align-items-center mb-3"
                >
                  <span class="text-light">Your cart</span>
                  <span class="badge badge-secondary badge-pill">{{
                    TotalItems
                  }}</span>
                </h4>
                <ul class="list-group mb-3">
                  <li
                    class="
                      list-group-item
                      d-flex
                      justify-content-between
                      lh-condensed
                    "
                    v-for="product in Cart"
                    :key="product.theProduct.id"
                  >
                    <div>
                      <h6 class="my-0">{{ product.theProduct.name }}</h6>
                      <small v-html="product.theProduct.description"></small>
                    </div>
                    <span class="text-light">$12</span>
                  </li>
                  <li class="list-group-item d-flex justify-content-between">
                    <span>Total (USD)</span>
                    <strong>{{ Total }}</strong>
                  </li>
                </ul>

                <!-- <form class="card p-2">
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Promo code"
                    />
                    <div class="input-group-append">
                      <button type="submit" class="btn btn-secondary">
                        Redeem
                      </button>
                    </div>
                  </div>
                </form> -->
              </div>

              <div class="col-md-8 order-md-1">
                <h4 class="mb-3 text-light">Shipping address</h4>
                <form
                  action="https://backoffice.amerusfinancialgroup.com/charge"
                  method="post"
                  id="payment-form"
                  class="needs-validation"
                  novalidate
                >
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="firstName" class="text-light"
                        >First name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="firstName"
                        placeholder=""
                        value=""
                        required
                        @input="firstname"
                      />
                      <div class="invalid-feedback">
                        Valid first name is required.
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="lastName" class="text-light">Last name</label>
                      <input
                        type="text"
                        class="form-control"
                        id="lastName"
                        placeholder=""
                        value=""
                        required
                        @input="lastname"
                      />
                      <div class="invalid-feedback">
                        Valid last name is required.
                      </div>
                    </div>
                  </div>

                  <!-- <div class="mb-3">
                    <label for="username" class="text-light">Username</label>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">@</span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        id="username"
                        placeholder="Username"
                        required
                      />
                      <div class="invalid-feedback" style="width: 100%">
                        Your username is required.
                      </div>
                    </div>
                  </div> -->

                  <div class="mb-3">
                    <label for="email" class="text-light"
                      >Email <span class="text-light">(Optional)</span></label
                    >
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="you@example.com"
                      @input="orderEmail"
                    />
                    <div class="invalid-feedback">
                      Please enter a valid email address for shipping updates.
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="email" class="text-light"
                      >Phone Number <span class="text-light"></span
                    ></label>
                    <input
                      type="tel"
                      class="form-control"
                      id="tel"
                      placeholder="555-555-5555"
                      @input="orderPhone"
                    />
                    <div class="invalid-feedback">
                      Please enter a valid phone number for shipping updates.
                    </div>
                  </div>

                  <div class="mb-3">
                    <label for="address" class="text-light">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      placeholder="1234 Main St"
                      required
                      @input="street"
                    />
                    <div class="invalid-feedback">
                      Please enter your shipping address.
                    </div>
                  </div>

                  <!-- <div class="mb-3">
                    <label for="address2" class="text-light"
                      >Address 2
                      <span class="text-light">(Optional)</span></label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="address2"
                      placeholder="Apartment or suite"
                    />
                  </div> -->
                  <div class="mb-3">
                    <label for="city" class="text-light"
                      >City <span class="text-light"></span
                    ></label>
                    <input
                      type="text"
                      class="form-control"
                      id="address2"
                      placeholder="City"
                      @input="city"
                    />
                  </div>

                  <div class="row">
                    <div class="col-md-4 mb-3">
                      <label for="state" class="text-light">State</label>
                      <select
                        class="custom-select d-block w-100"
                        id="state"
                        required
                        @input="state"
                      >
                        <!-- <option value="">Choose...</option> -->
                        <option value="" selected="selected">
                          Select a State
                        </option>
                        <option value="AL">Alabama</option>
                        <option value="AK">Alaska</option>
                        <option value="AZ">Arizona</option>
                        <option value="AR">Arkansas</option>
                        <option value="CA">California</option>
                        <option value="CO">Colorado</option>
                        <option value="CT">Connecticut</option>
                        <option value="DE">Delaware</option>
                        <option value="DC">District Of Columbia</option>
                        <option value="FL">Florida</option>
                        <option value="GA">Georgia</option>
                        <option value="HI">Hawaii</option>
                        <option value="ID">Idaho</option>
                        <option value="IL">Illinois</option>
                        <option value="IN">Indiana</option>
                        <option value="IA">Iowa</option>
                        <option value="KS">Kansas</option>
                        <option value="KY">Kentucky</option>
                        <option value="LA">Louisiana</option>
                        <option value="ME">Maine</option>
                        <option value="MD">Maryland</option>
                        <option value="MA">Massachusetts</option>
                        <option value="MI">Michigan</option>
                        <option value="MN">Minnesota</option>
                        <option value="MS">Mississippi</option>
                        <option value="MO">Missouri</option>
                        <option value="MT">Montana</option>
                        <option value="NE">Nebraska</option>
                        <option value="NV">Nevada</option>
                        <option value="NH">New Hampshire</option>
                        <option value="NJ">New Jersey</option>
                        <option value="NM">New Mexico</option>
                        <option value="NY">New York</option>
                        <option value="NC">North Carolina</option>
                        <option value="ND">North Dakota</option>
                        <option value="OH">Ohio</option>
                        <option value="OK">Oklahoma</option>
                        <option value="OR">Oregon</option>
                        <option value="PA">Pennsylvania</option>
                        <option value="RI">Rhode Island</option>
                        <option value="SC">South Carolina</option>
                        <option value="SD">South Dakota</option>
                        <option value="TN">Tennessee</option>
                        <option value="TX">Texas</option>
                        <option value="UT">Utah</option>
                        <option value="VT">Vermont</option>
                        <option value="VA">Virginia</option>
                        <option value="WA">Washington</option>
                        <option value="WV">West Virginia</option>
                        <option value="WI">Wisconsin</option>
                        <option value="WY">Wyoming</option>
                      </select>
                      <div class="invalid-feedback">
                        Please provide a valid state.
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="zip" class="text-light">Zip</label>
                      <input
                        type="text"
                        class="form-control"
                        id="zip"
                        placeholder=""
                        required
                        @input="zip"
                      />
                      <div class="invalid-feedback">Zip code required.</div>
                    </div>
                  </div>
                  <hr class="mb-4" />
                  <!-- <div class="custom-control text-left custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="same-address"
                    />
                    <label
                      class="custom-control-label text-light"
                      for="same-address"
                      >Shipping address is the same as my billing address</label
                    >
                  </div> -->
                  <label for="card-number"></label>
                  <div id="card-number" class="form-control"></div>
                  <div id="card-errors" role="alert"></div>

                  <button class="btn btn-primary w-100 mb-3 mt-3">
                    Submit Payment
                  </button>
                  <!-- <div class="custom-control text-left custom-checkbox">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="save-info"
                    />
                    <label
                      class="custom-control-label text-light"
                      for="save-info"
                      >Save this information for next time</label
                    >
                  </div>
                  <hr class="mb-4" />

                  <h4 class="mb-3 text-light text-left">Payment</h4>

                  <div class="d-block my-3">
                    <div class="custom-control text-left custom-radio">
                      <input
                        id="credit"
                        name="paymentMethod"
                        type="radio"
                        class="custom-control-input"
                        checked
                        required
                      />
                      <label
                        class="custom-control-label text-light"
                        for="credit"
                        >Credit card</label
                      >
                    </div>
                    <div class="custom-control text-left custom-radio">
                      <input
                        id="debit"
                        name="paymentMethod"
                        type="radio"
                        class="custom-control-input"
                        required
                      />
                      <label class="custom-control-label text-light" for="debit"
                        >Debit card</label
                      >
                    </div>
                    <div class="custom-control text-left custom-radio">
                      <input
                        id="paypal"
                        name="paymentMethod"
                        type="radio"
                        class="custom-control-input"
                        required
                      />
                      <label
                        class="custom-control-label text-light"
                        for="paypal"
                        >Paypal</label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="cc-name" class="text-light"
                        >Name on card</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="cc-name"
                        placeholder=""
                        required
                      />
                      <small class="text-light"
                        >Full name as displayed on card</small
                      >
                      <div class="invalid-feedback">
                        Name on card is required
                      </div>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="cc-number" class="text-light"
                        >Credit card number</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="cc-number"
                        placeholder=""
                        required
                      />
                      <div class="invalid-feedback">
                        Credit card number is required
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3 mb-3">
                      <label for="cc-expiration" class="text-light"
                        >Expiration</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="cc-expiration"
                        placeholder=""
                        required
                      />
                      <div class="invalid-feedback">
                        Expiration date required
                      </div>
                    </div>
                    <div class="col-md-3 mb-3">
                      <label for="cc-expiration" class="text-light">CVV</label>
                      <input
                        type="text"
                        class="form-control"
                        id="cc-cvv"
                        placeholder=""
                        required
                      />
                      <div class="invalid-feedback">Security code required</div>
                    </div>
                  </div>
                  <hr class="mb-4" />
                  <button
                    class="btn btn-primary btn-lg btn-block"
                    type="submit"
                    @click.prevent="createOrder"
                  >
                    Purchase
                  </button> -->
                  <a
                    href="https://amerusfinancialgroup.com/lead-order-form/"
                    class="btn btn-secondary btn-lg btn-block"
                    target="_blank"
                    >Use Amerus Bucks</a
                  >
                </form>
              </div>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>

  <!-- <StripeCard /> -->
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
// import StripeCard from "../components/StripeCard";
import { SidebarMenu } from "vue-sidebar-menu";

import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "CheckOut",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    orderPhone(e) {
      this.$store.commit("setOrderPhone", e.target.value);
    },
    orderEmail(e) {
      this.$store.commit("setOrderEmail", e.target.value);
    },
    zip(e) {
      this.$store.commit("setZip", e.target.value);
    },
    state(e) {
      this.$store.commit("setState", e.target.value);
    },
    city(e) {
      this.$store.commit("setCity", e.target.value);
    },
    street(e) {
      this.$store.commit("setStreet", e.target.value);
    },
    firstname(e) {
      this.$store.commit("setUserFirstName", e.target.value);
    },
    lastname(e) {
      this.$store.commit("setUserLastName", e.target.value);
    },
    async setupStripe() {
      // setup Stripe.js and Elements
      const stripe = await loadStripe(
        "pk_live_LwQbn0U0TdynQAZmTuvRCuuR00b1FCz8Kq"
      );
      const elements = stripe.elements();
      // setup card Element
      const cardElement = elements.create("card", {});
      cardElement.mount("#card-number");

      cardElement.addEventListener("change", function (event) {
        var displayError = document.getElementById("card-errors");
        if (event.error) {
          displayError.textContent = event.error.message;
        } else {
          displayError.textContent = "";
        }
      });

      //Handle form submission
      var context = this;
      var stripeForm = document.getElementById("payment-form");
      stripeForm.addEventListener("submit", function (event) {
        event.preventDefault();

        stripe.createToken(cardElement).then(function (result) {
          if (result.error) {
            //Inform the user if thre was an error
            var errorElement = document.getElementById("card-errors");
            errorElement.textContent = result.error.message;
          } else {
            //Send the token to your server
            context.stripeTokenHandler(result.token);
          }
        });
      });
    },
    stripeDescriptionHandler(description) {
      var form = document.getElementById("payment-form");
      var hiddenDescriptionInput = document.createElement("input");
      hiddenDescriptionInput.setAttribute("type", "hidden");
      hiddenDescriptionInput.setAttribute("name", "description");
      hiddenDescriptionInput.setAttribute("value", description);
      form.appendChild(hiddenDescriptionInput);
    },
    stripePriceHandler(Total) {
      var form = document.getElementById("payment-form");
      var hiddenPriceInput = document.createElement("input");
      hiddenPriceInput.setAttribute("type", "hidden");
      hiddenPriceInput.setAttribute("name", "price");
      hiddenPriceInput.setAttribute("value", Total);
      form.appendChild(hiddenPriceInput);
    },
    stripeTokenHandler(token) {
      //submit the form with the token ID
      //insert the token ID into the form so it gets submitted to the server
      var form = document.getElementById("payment-form");
      var hiddenInput = document.createElement("input");
      hiddenInput.setAttribute("type", "hidden");
      hiddenInput.setAttribute("name", "stripeToken");
      hiddenInput.setAttribute("value", token.id);
      form.appendChild(hiddenInput);

      //submit the form
      form.submit();
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    name() {
      var name = this.$store.getters.getName;
      return name;
    },
    email() {
      return this.$store.getters.getEmail;
    },
    Menu() {
      return this.$store.getters.getMenu;
    },
    Cart() {
      return this.$store.getters.getCart;
    },
    Total() {
      // return this.$store.getters.getTotalPrice.slice(-1);
      var total = 0;
      for (let i = 0; i < this.Cart.length; i++) {
        total += parseInt(this.Cart[i].theProduct.price);
      }
      console.log(total);
      return total;
    },
    TotalItems() {
      return this.Cart.length;
    },
  },
  mounted() {
    this.setupStripe();
    this.stripePriceHandler(this.Total);
    this.stripeDescriptionHandler("Payment for: " + this.name);
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
  box-shadow: rgb(0 0 0) 0px 5px 20px 5px !important;
  background: padding-box;
}
p,
h5 {
  color: #fff;
}

.card-body {
  background-color: #2a2a2a;
}
.card {
  box-shadow: none;
}
</style>
