<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0 px-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="black p-3 pt-5">
            <div class="row mb-3">
              <h1 class="text-light mb-5 w-100 text-center">
                MEET YOUR AGENT SUCCESS TEAM:
              </h1>
              <img
                src="../assets/hero-mobile.jpg"
                alt="banner"
                class="img-fluid d-md-none"
              />
              <img
                src="../assets/hero-desktop.jpg"
                alt="banner"
                class="img-fluid d-none d-md-block"
              />
            </div>
            <div class="row mb-3">
              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Tim Baggett</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    President and CEO
                  </p>
                  <p class="text-left mb-0">(888) 441-7891</p>
                  <p class="text-left mb-0">timbaggett@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/TimBaggett.jpg"
                    alt="Tim Baggett"
                    class="img-fluid"
                  />
                </div>
              </div>

              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Rob Skinner</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    Vice President and National Sales Director
                  </p>
                  <p class="text-left mb-0">(888) 441-7891</p>
                  <p class="text-left mb-0">robskinner@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/RobSkinner.jpg"
                    alt="Rob Skinner"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Nashia Trinchet (Nina)</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    Contracting and Onboarding Support
                  </p>
                  <p class="text-left mb-0">(888) 441-7891 x 703</p>
                  <p class="text-left mb-0">contracting@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Nina.jpg"
                    alt="Nashia Trinchet"
                    class="img-fluid"
                  />
                </div>
              </div>

              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Wayne Caraway</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">Leads</p>
                  <p class="text-left mb-0">863-288-0991</p>
                  <p class="text-left mb-0">leads@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Wayne.jpg"
                    alt="Wayne Caraway"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Abigail Baggett</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    Group and Field Sales Support
                  </p>
                  <p class="text-left mb-0">(888) 441-7891 x 701</p>
                  <p class="text-left mb-0">agentsupport@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Abigail.jpg"
                    alt="Abigail Baggett"
                    class="img-fluid"
                  />
                </div>
              </div>

              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Leah Dugger</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    ACA Enrollment and Field Admin Support
                  </p>
                  <p class="text-left mb-0">(888) 441-7891 x 702</p>
                  <p class="text-left mb-0">support@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Leah.jpg"
                    alt="Leah Dugger"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Curtis Dugger</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">Commissions</p>
                  <p class="text-left mb-0">(888) 441-7891 x 709</p>
                  <p class="text-left mb-0">curtisdugger@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Curtis.jpg"
                    alt="Curtis Dugger"
                    class="img-fluid"
                  />
                </div>
              </div>

              <div class="col-md-12 col-lg-6 mb-3 pb-3">
                <div class="col-8 float-right">
                  <h2 class="text-left amerus-grey">
                    <strong>Kimberly Skinner</strong>
                  </h2>
                  <p class="lead text-left mb-0 amerus-blue">
                    Agent Development, Back Office Support
                  </p>
                  <p class="text-left mb-0">(888) 441-7891 x 100</p>
                  <p class="text-left mb-0">kimberly@amerusfinancial.com</p>
                </div>
                <div class="col-4 float-left">
                  <img
                    src="../assets/Kim.jpg"
                    alt="Kimberly Skinner"
                    class="img-fluid"
                  />
                </div>
              </div>
            </div>
            <a @click="$router.go(-1)" class="btn btn-primary mt-4"
              ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "CompanyInfo",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
}
p,
h5 {
  color: #fff;
}
</style>
