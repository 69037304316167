<template>
  <div>
    <canvas :id="elementID"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "BarChart",
  props: {
    element: String,
    ChartBackgroundColor: String,
    ChartBorderColor: String,
    ChartLabels: [Array, Object],
    ChartDataSet: [Array, Object],
  },
  data() {
    return {
      elementID: this.element,
      barChartData: {
        type: "bar",
        data: {
          labels: this.ChartLabels,
          datasets: [
            {
              label: "Amerus Bucks",
              data: this.ChartDataSet,
              backgroundColor: this.ChartBackgroundColor,
              //borderColor: this.ChartBorderColor,
              borderWidth: 3,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    };
  },

  mounted() {
    const ctx = document.getElementById(this.element);
    const barChart = new Chart(ctx, this.barChartData);
    barChart.update();
  },
};
</script>