<template>
  <div class="team">
    <h1>This is your team page!</h1>
    <a @click="$router.go(-1)">Return</a>
  </div>
</template>

<script>
export default { name: "Team" };
</script>
