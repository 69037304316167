<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="row-fluid">
            <h1 class="text-light">Store</h1>
            <div class="card-deck">
              <div
                class="col-md-12 col-lg-3 p-2"
                v-for="product in Products"
                :key="product.id"
              >
                <div class="card m-0 p-0">
                  <img
                    v-for="image in product.images"
                    :key="image.id"
                    :src="image.src"
                    :alt="image.alt"
                    width="420"
                    height="255"
                    class="card-img-top img-fluid"
                  />
                  <div class="card-body text-left pt-2 pl-0">
                    <h5 class="card-title">{{ product.name }}</h5>
                    <p class="strong">{{ "$" + product.price }}</p>
                    <!-- <p class="card-text" v-html="product.description"></p> -->
                    <div class="btn-group" role="group" aria-label="...">
                      <router-link
                        class="btn btn-outline-primary"
                        :to="{ path: '/store/' + product.id }"
                      >
                        View Product
                      </router-link>
                      <!-- <p class="card-text">
                        <button
                          class="btn btn-primary"
                          @click="addToCart(product)"
                        >
                          Add To Cart
                        </button>
                      </p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
        <router-link class="nav-link link-dark p-0" to="/checkout"
          >Check Out</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "Store",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    productUrl() {
      // not working
      // need to get product id
      return (
        "https://backoffice.amerusfinancialgroup.com/store/" + this.Products.id
      );
    },
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
    addToCart(product) {
      console.log(product);
      this.$store.dispatch("addToCart", {
        theProduct: product,
      });
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
    Products() {
      return this.$store.getters.getAmerusProducts;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
  box-shadow: rgb(0 0 0) 0px 5px 20px 5px !important;
  background: padding-box;
}
p,
h5 {
  font-family: open sans-serif;
  color: #fff;
}

/* .card-body {
  background-color: #2a2a2a;
} */
.card {
  box-shadow: none;
}
</style>
