<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="row-fluid">
            <div class="col-md-12 pl-0 pr-0">
              <div class="card">
                <div
                  class="card-body bg-primary text-white mailbox-widget pb-0"
                >
                  <h1 class="text-white pb-3">News And Updates</h1>
                  <ul
                    class="nav nav-tabs custom-tab border-bottom-0 mt-4"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        id="inbox-tab"
                        data-toggle="tab"
                        aria-controls="inbox"
                        href="#inbox"
                        role="tab"
                        aria-selected="true"
                      >
                        <span class="d-block d-md-none"
                          ><i class="ti-email"></i
                        ></span>
                        <span class="d-none d-md-block"> INBOX</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="sent-tab"
                        data-toggle="tab"
                        aria-controls="sent"
                        href="#sent"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="d-block d-md-none"
                          ><i class="ti-export"></i
                        ></span>
                        <span class="d-none d-md-block">SENT</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="spam-tab"
                        data-toggle="tab"
                        aria-controls="spam"
                        href="#spam"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="d-block d-md-none"
                          ><i class="ti-panel"></i
                        ></span>
                        <span class="d-none d-md-block">SPAM</span>
                      </a>
                    </li>
                    <li class="nav-item">
                      <a
                        class="nav-link"
                        id="delete-tab"
                        data-toggle="tab"
                        aria-controls="delete"
                        href="#delete"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="d-block d-md-none"
                          ><i class="ti-trash"></i
                        ></span>
                        <span class="d-none d-md-block">DELETED</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="tab-content inbox text-light" id="myTabContent">
                  <div
                    class="tab-pane fade active show"
                    id="inbox"
                    aria-labelledby="inbox-tab"
                    role="tabpanel"
                  >
                    <div>
                      <div class="row p-4 no-gutters align-items-center">
                        <div class="col-sm-12 col-md-6">
                          <h3 class="font-light mb-0">
                            <i class="ti-email mr-2"></i>350 Unread emails
                          </h3>
                        </div>
                        <div class="col-sm-12 col-md-6">
                          <ul
                            class="
                              list-inline
                              dl
                              mb-0
                              float-left float-md-right
                            "
                          >
                            <li class="list-inline-item text-info mr-3">
                              <a href="#">
                                <button
                                  class="btn btn-circle btn-success text-white"
                                  href="javascript:void(0)"
                                >
                                  <i class="fa fa-plus"></i>
                                </button>
                                <span class="ml-2 font-normal text-light"
                                  >Compose</span
                                >
                              </a>
                            </li>
                            <li class="list-inline-item text-danger">
                              <a href="#">
                                <button
                                  class="btn btn-circle btn-danger text-white"
                                  href="javascript:void(0)"
                                >
                                  <i class="fa fa-trash"></i>
                                </button>
                                <span class="ml-2 font-normal text-light"
                                  >Delete</span
                                >
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- Mail list-->
                      <div class="table-responsive">
                        <table
                          class="
                            table
                            email-table
                            no-wrap
                            table-hover
                            v-middle
                            mb-0
                            font-14
                          "
                        >
                          <tbody>
                            <!-- row -->
                            <tr>
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst1"
                                  />
                                  <label class="custom-control-label" for="cst1"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star text-warning"></i></td>
                              <td>
                                <span class="mb-0">Tim Baggett</span>
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-danger
                                      mr-2
                                    "
                                    >Presidents Message</span
                                  >
                                  <span class="text-light"
                                    >Video One Message-</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="">May 13</td>
                            </tr>
                            <!-- row -->
                            <tr>
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst2"
                                  />
                                  <label class="custom-control-label" for="cst2"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star"></i></td>
                              <!-- User -->
                              <td>
                                <span class="mb-0">Genelia Roshan</span>
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-info
                                      mr-2
                                    "
                                    >Business</span
                                  >
                                  <span class="text-light"
                                    >Inquiry about license for Admin
                                  </span>
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="">May 13</td>
                            </tr>
                            <!-- row -->
                            <tr>
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst3"
                                  />
                                  <label class="custom-control-label" for="cst3"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star text-warning"></i></td>
                              <!-- User -->
                              <td class="user-name max-texts">
                                <span class="mb-0 font-light"
                                  >Ritesh Deshmukh</span
                                >
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-warning
                                      mr-2
                                    "
                                    >Friend</span
                                  >
                                  <span class="font-light text-light"
                                    >Bitbucket (commit Pushed) by Ritesh</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="font-light">May 13</td>
                            </tr>
                            <!-- row -->
                            <tr class="">
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst4"
                                  />
                                  <label class="custom-control-label" for="cst4"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star"></i></td>
                              <!-- User -->
                              <td>
                                <span class="mb-0 font-light"
                                  >Akshay Kumar</span
                                >
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-info
                                      mr-2
                                    "
                                    >Work</span
                                  ><span class="font-light text-light"
                                    >Perspiciatis unde omnis- iste Lorem
                                    ipsum</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="font-light">May 9</td>
                            </tr>
                            <!-- row -->
                            <tr class="">
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst5"
                                  />
                                  <label class="custom-control-label" for="cst5"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star"></i></td>
                              <!-- User -->
                              <td>
                                <span class="mb-0 font-light"
                                  >John Abraham</span
                                >
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-success
                                      mr-2
                                    "
                                    >Work</span
                                  >
                                  <span class="font-light text-light"
                                    >Lorem ipsum perspiciatis- unde omnis</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="font-light">Mar 10</td>
                            </tr>
                            <!-- row -->
                            <tr class="">
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst6"
                                  />
                                  <label class="custom-control-label" for="cst6"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star text-warning"></i></td>
                              <!-- User -->
                              <td>
                                <span class="mb-0 font-light"
                                  >Akshay Kumar</span
                                >
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-success
                                      mr-2
                                    "
                                    >Work</span
                                  >
                                  <span class="font-light text-light"
                                    >Lorem ipsum perspiciatis - unde</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="font-light">Mar 09</td>
                            </tr>
                            <!-- row -->
                            <tr class="">
                              <!-- label -->
                              <td class="pl-3">
                                <div class="custom-control custom-checkbox">
                                  <input
                                    type="checkbox"
                                    class="custom-control-input"
                                    id="cst7"
                                  />
                                  <label class="custom-control-label" for="cst7"
                                    >&nbsp;</label
                                  >
                                </div>
                              </td>
                              <!-- star -->
                              <td><i class="fa fa-star text-warning"></i></td>
                              <!-- User -->
                              <td>
                                <span class="mb-0 font-light">Hanna Gover</span>
                              </td>
                              <!-- Message -->
                              <td>
                                <a class="link" href="javascript: void(0)">
                                  <span
                                    class="
                                      badge badge-pill
                                      text-white
                                      font-medium
                                      badge-danger
                                      mr-2
                                    "
                                    >Work</span
                                  ><span class="font-light text-light">
                                    Unde omnis Lorem ipsum perspiciatis</span
                                  >
                                </a>
                              </td>
                              <!-- Attachment -->
                              <td>
                                <i class="fa fa-paperclip"></i>
                              </td>
                              <!-- Time -->
                              <td class="font-light">Mar 09</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="sent"
                    aria-labelledby="sent-tab"
                    role="tabpanel"
                  >
                    <div class="row p-3 text-light">
                      <div class="col-md-6">
                        <h3 class="font-light">Lets check profile</h3>
                        <h4 class="font-light">
                          you can use it with the small code
                        </h4>
                      </div>
                      <div class="col-md-6 text-right">
                        <p>
                          Donec pede justo, fringilla vel, aliquet nec,
                          vulputate eget, arcu. In enim justo, rhoncus ut,
                          imperdiet a.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="spam"
                    aria-labelledby="spam-tab"
                    role="tabpanel"
                  >
                    <div class="row p-3 text-light">
                      <div class="col-md-6">
                        <h3 class="font-light">
                          Come on you have a lot message
                        </h3>
                        <h4 class="font-light">
                          you can use it with the small code
                        </h4>
                      </div>
                      <div class="col-md-6 text-right">
                        <p>
                          Donec pede justo, fringilla vel, aliquet nec,
                          vulputate eget, arcu. In enim justo, rhoncus ut,
                          imperdiet a.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="delete"
                    aria-labelledby="delete-tab"
                    role="tabpanel"
                  >
                    <div class="row p-3 text-light">
                      <div class="col-md-6">
                        <h3 class="font-light">Just do Settings</h3>
                        <h4 class="font-light">
                          you can use it with the small code
                        </h4>
                      </div>
                      <div class="col-md-6 text-right">
                        <p>
                          Donec pede justo, fringilla vel, aliquet nec,
                          vulputate eget, arcu. In enim justo, rhoncus ut,
                          imperdiet a.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "Messages",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
}
p,
h5 {
  color: #fff;
}

.card-body {
  background: #28273f;
}
.card {
  box-shadow: none;
}
.inbox {
  background: #2a2a2a;
}
.nav-link {
  color: #fff;
}
</style>
