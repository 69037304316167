<template>
  <div id="content" class="demo col pt-0">
    <navbar />
    <Welcome />
    <HorizontalToolBar />
    <div class="row-fluid">
      <div class="card-deck">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Annual Auction</h5>
            <MultiLineChart
              element="MultiLineChart2"
              ChartBackgroundColor1="rgba(255,0,0,0.1)"
              ChartBackgroundColor2="rgb(255, 145, 36, 0.1)"
              ChartBorderColor1="#9e5d35"
              ChartBorderColor2="#FFC233"
              :ChartLabels="[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ]"
              :SalesData="[4, 10, 4, 10, 18, 12, 24, 16, 22, 20, 2]"
              :RevenueData="[
                40, 100, 40, 100, 180, 120, 240, 160, 220, 200, 20,
              ]"
              :key="1"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Car Bonus</h5>
            <MultiLineChart
              element="MultiLineChart3"
              ChartBackgroundColor1="rgba(255,0,0,0.1)"
              ChartBackgroundColor2="rgb(255, 145, 36, 0.1)"
              ChartBorderColor1="#9e5d35"
              ChartBorderColor2="#FFC233"
              :ChartLabels="[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ]"
              :SalesData="[1, 2, 1, 4, 5, 3, 6, 4, 3, 5, 4]"
              :RevenueData="[10, 20, 10, 40, 50, 30, 60, 40, 30, 50, 40]"
              :key="2"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row-fluid mt-4">
      <div class="card-deck">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Trip Bonus</h5>
            <MultiLineChart
              element="MultiLineChart4"
              ChartBackgroundColor1="rgba(255,0,0,0.1)"
              ChartBackgroundColor2="rgb(255, 145, 36, 0.1)"
              ChartBorderColor1="#9e5d35"
              ChartBorderColor2="#FFC233"
              :ChartLabels="[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ]"
              :SalesData="[3, 5, 2, 1, 7, 6, 2, 4, 6, 6, 4]"
              :RevenueData="[30, 50, 20, 10, 70, 60, 20, 40, 60, 60, 40]"
              :key="3"
            />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Pool Bonus</h5>
            <MultiLineChart
              element="MultiLineChart5"
              ChartBackgroundColor1="rgba(255,0,0,0.1)"
              ChartBackgroundColor2="rgb(255, 145, 36, 0.1)"
              ChartBorderColor1="#9e5d35"
              ChartBorderColor2="#FFC233"
              :ChartLabels="[
                'January',
                'February',
                'March',
                'April',
                'May',
                'June',
                'July',
                'August',
                'September',
                'October',
                'November',
                'December',
              ]"
              :SalesData="[7, 4, 8, 2, 8, 4, 11, 3, 5, 5, 8]"
              :RevenueData="[70, 40, 80, 20, 80, 40, 110, 30, 50, 50, 80]"
              :key="4"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "./Welcome";
import HorizontalToolBar from "../components/HorizontalToolBar";
import MultiLineChart from "../components/MultiLineChart";
export default {
  components: {
    Navbar,
    HorizontalToolBar,
    Welcome,
    MultiLineChart,
  },
  computed: {},
  methods: {},
  created() {},
};
</script>

<style scoped>
.card {
  background-color: #2a2a2a;
}
.card-title {
  color: #afafb4;
}
</style>