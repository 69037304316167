import { createRouter, createWebHistory } from 'vue-router'

import Dashboard from '../views/Dashboard.vue'
import CompensationBonuses from '../views/CompensationBonuses.vue'
import Login from '../views/Login.vue'
import Ranking from '../views/Ranking.vue'
import Events from '../views/Events.vue'
import AgentSupport from '../views/AgentSupport.vue'
import AmerusBucks from '../views/AmerusBucks.vue'
import Leaderboard from '../views/Leaderboard.vue'
import Profile from '../views/Profile.vue'
import Team from '../views/Team.vue'
import DistrictManager from '../views/DistrictManager.vue'
import RegionalManager from '../views/RegionalManager.vue'
import AmerusJournal from '../views/AmerusJournal.vue'
import QuotingAccess from '../views/QuotingAccess.vue'
import Analytics from '../views/Analytics.vue'
import Messages from '../views/Messages.vue'
import Cart from '../views/Cart.vue'
import Store from '../views/Store.vue'
import CheckOut from '../views/CheckOut.vue'
import Loading from '../views/Loading.vue'
import Product from '../views/Product.vue'
import Thanks from '../views/Thanks.vue'
import Carriers from '../views/Carriers.vue'
import Train from '../views/Train.vue'

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/loading',
    name: 'Loading',
    component: Loading,
    
    async beforeEnter(to, from, next) {
      try {
        
          next()
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/CompensationBonuses',
    name: 'CompensationBonuses',
    component: CompensationBonuses,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/Events',
    name: 'Events',
    component: Events,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/AgentSupport',
    name: 'AgentSupport',
    component: AgentSupport,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/amerusbucks',
    name: 'AmerusBucks',
    component: AmerusBucks,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/AmerusJournal',
    name: 'AmerusJournal',
    component: AmerusJournal,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/quotingaccess',
    name: 'QuotingAccess',
    component: QuotingAccess,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/team',
    name: 'Team',
    component: Team,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/district-manager',
    name: 'DistrictManager',
    component: DistrictManager,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/regional-manager',
    name: 'RegionalManager',
    component: RegionalManager,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/analytics',
    name: 'Annual Production (Analytics)',
    component: Analytics,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/messages',
    name: 'Messages',
    component: Messages,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/store',
    name: 'Store',
    component: Store,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/store/:id',
    name: 'Product',
    component: Product,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/checkout',
    name: 'CheckOut',
    component: CheckOut,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/thanks',
    name: 'Thanks',
    component: Thanks,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Login", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/Carriers',
    name: 'Carriers',
    component: Carriers,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Carriers", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },

  {
    path: '/Train',
    name: 'Train',
    component: Train,
    async beforeEnter(to, from, next) {
      try {
        if (sessionStorage.getItem('JWT') != undefined) {
          next()
        }else{
          next({
            name: "Carriers", // back to safety route //
            query: { redirectFrom: to.fullPath }
          })
        }
      } 
      catch (e) {
        console.log(e);
      }
    }
  },
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
