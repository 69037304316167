<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <navbar />
        <Welcome />
        <div class="row-fluid">
          <!-- 16:9 aspect ratio -->
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%231f1c30&ctz=America%2FNew_York&showTitle=0&showNav=0&showDate=0&showPrint=0&showCalendars=0&showTz=0&showTabs=0&src=ZG9uYXZhbkBidXNpbmVzc2JlbmVmaXRhbGxpYW5jZS5jb20&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=Y183ZzQxbjduajBuNWRwM2RzMGZiYnAybGpsa0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZW4udXNhI2hvbGlkYXlAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&color=%23039BE5&color=%2333B679&color=%23B39DDB&color=%230B8043"
              style="border: solid 1px #777"
              width="800"
              height="600"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>
        </div>

        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "Events",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
</style>
