<template>
  <div>
    <canvas :id="elementID"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "MultiSeriesPieChart",
  props: { element: String },
  data() {
    return {
      elementID: this.element,
      chartData: {
        type: "pie",
        data: {
          labels: ["Annual Auction", "Car Bonus", "Trip Bonus", "Pool Bonus"],
          datasets: [
            {
              label: "Number of Moons",
              data: [79, 82, 27, 14],
              backgroundColor: [
                "rgb(255, 99, 132)",
                "rgb(255, 159, 64)",
                "rgb(255, 205, 86)",
                "rgb(75, 192, 192)",
                "rgb(54, 162, 235)",
              ],
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.element);
    new Chart(ctx, this.chartData);
    //console.log(this.chartData);
  },
};
</script>