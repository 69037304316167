import { createStore } from "vuex";
import createPersistedState from 'vuex-persistedstate'
import WooCommerceRestApi from "@woocommerce/woocommerce-rest-api";
import router from '../router'


// import JSONSALES from "../../public/json/sales.json";
export default createStore({
  state:{
    Error:'',
    firstName:"",
    orderPhone:"",
    lastName:"",
    street:"",
    city:"",
    state:"",
    zip:"",
    orderEmail:"",
    ModalState: true,
    HasPermission: false,
    Token:"",
    AccessToken:"",
    userId:"",
    username:"",
    password:"",
    points:"",
    spins:"",
    AmerusBucks: 0,
    AmerusBucksCredit: 0,
    Premium: 0,
    PremiumCredit: 0,
    BonusPool: 0,
    TripBonus:0,
    TripBonusCredit: 0,
    CarBonus:0,
    CarBonusCredit: 0,
    AnnualAuction:0,
    AnnualAuctionCredit: 0,
    ProfileYear: 0,
    ProfileQuarter: 0,
    Email:"",
    MobileNumber:"",
    Location:"",
    Agent:{},
    Products:[],
    VariationProductPrice:0,
    Cart:[],
    ProfileImage:"",
    Facebook:"",
    Twitter:"",
    LinkedIn:"",
    YouTube:"",
    TheAgentType: 0,
    StartDate: "",
    Progress: 0,
    CurrentRank: 0,
    NextRank: 0,
    SalesSinceHire: 0,
    RevenueSinceHire: 0,
    TotalOfEachSale:[],
    LabelOfEachSale:[
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    TotalSales:[],
    TodaysSales:[],
    YesterdaysSales:[],
    LastWeeksSales:[],
    LastMonthsSales:[],
    ThisQuarterSales:[],
    Posts:[],
    Menu: [
      {
        href: "/dashboard",
        title: "Dashboard",
        icon: "fas fa-home",
      },
      {
        href: "/analytics",
        title: "Annual Production (Analytics)",
        icon: "fas fa-money-bill",
      },
      {
        href: "/district-manager",
        title: "District Manager",
        icon: "fas fa-user-tie",
      },
      {
        href: "/regional-manager",
        title: "Regional Manager",
        icon: "fas fa-user",
      },
    ],
    collapsed: true,
    themes: [
      {
        name: "Default theme",
        input: "",
      },
      {
        name: "White theme",
        input: "white-theme",
      },
    ],
    selectedTheme: "white-theme",
    isOnMobile: false,
  },
  getters:{
    getProfileQuarter(state){
      return state.ProfileQuarter
    },
    getProfileYear(state){
      return state.ProfileYear
    },
    getUserCarBonusCredit(state){
      return state.CarBonusCredit
    },
    getUserTripBonusCredit(state){
      return state.TripBonusCredit
    },
    getUserAccessToken(state){
      return state.AccessToken
    },
    getAmerusBucksCredit(state){
      return state.AmerusBucksCredit
    },
    getAmerusBucks(state, getters){
      if(getters.getAmerusBucksCredit > 0){
        return getters.getAmerusBucksCredit + state.AmerusBucks
      }else{
        return state.AmerusBucks;
      }
      
    },
    getError(state){
      return state.Error
    },
    getBonusPool(state){
      return state.BonusPool
    },
    getTripBonus(state){
      return state.TripBonus
    },
    getCarBonus(state){
      return state.CarBonus
    },
    getAnnualAuction(state){
      return state.AnnualAuction
    },
    getAnnualAuctionCredit(state){
      return state.AnnualAuctionCredit;
    },
    getVariationProductPrice(state){
      return state.VariationProductPrice;
    },
    getPremium(state){
      return state.Premium;
    },
    getPremiumCredit(state){
      return state.PremiumCredit;
    },
    getOrderPhone(state){
      return state.orderPhone;
    },
    getOrderEmail(state){
      return state.orderEmail;
    },
    getZip(state){
      return state.zip;
    },
    getState(state){
      return state.state;
    },
    getCity(state){
      return state.city;
    },
    getStreet(state){
      return state.street;
    },
    getFirstName(state){
      return state.firstName;
    },
    getLastName(state){
      return state.lastName;
    },
    getMenu(state){
      return state.Menu;
    },
    getPoints(state){
      return state.points;
    },
    getSpins(state){
      return state.spins;
    },
    getModalState(state){
      return state.ModalState;
    },
    getCart(state){
      return state.Cart;
    },
    getAmerusProducts(state){
      return state.Products;
    },
    getLocation(state){
      return state.Location;
    },
    getFacebook(state){
      return state.Facebook;
    },
    getTwitter(state){
      return state.Twitter;
    },
    getLinkedIn(state){
      return state.LinkedIn;
    },
    getYouTube(state){
      return state.YouTube;
    },
    getLabelOfEachSale(state){
      return state.LabelOfEachSale;
    },
    getTotalOfEachSale(state){
      //console.log("state.TotalOfEachSale: " + state.TotalOfEachSale);
      return state.TotalOfEachSale;
    },
    getUserId(state){
      return state.userId;
    },
    getUserName(state){
      return state.username;
    },
    getEmail(state){
      return state.Email;
    },
    getPassword(state){
      return state.password;
    },
    getHasPermission(state){
      return state.HasPermission;
    },
    getToken(state){
      return state.Token;
    },
    getAmerusJounral(state){
      return state.Posts;
    },
    getAgentStartDate(state){
      return state.StartDate;
    },
    getAgentType(state){
      return state.TheAgentType;
    },
    getName(state){
      return state.Agent.name;
    },
    getAgentEmail(state){
      return state.Agent.user_email;
    },
    getMobileNumber(state){
      return state.MobileNumber;
    },
    getRevenueSinceHire(state){
      return state.RevenueSinceHire;
    },
    getProgress(state){
      return state.Progress;
    },
    getNextRank(state){
      return state.NextRank;
    },
    getCurrentRank(state){
      return state.CurrentRank;
    },
    getSalesSinceHire(state){
      return state.SalesSinceHire;
    },
    getProfileImage(state){
      return state.ProfileImage;
    },
  },
  mutations: {
    setProfileQuarter(state, payload){
      state.ProfileQuarter = payload.Quarter
    },
    setProfileYear(state, payload){
      state.ProfileYear = payload.Year
    },
    setUserCarBonusCredit(state, payload){
      state.CarBonusCredit = payload.setUserCarBonusCredit
    },
    setUserTripBonusCredit(state, payload){
      state.TripBonusCredit = payload.setUserTripBonusCredit
    },
    setUserAnnualAuction(state, payload){
      state.AnnualAuctionCredit = payload.AnnualAuction
    },
    setUserPremium(state, payload){
      state.PremiumCredit = payload.Premium
    },
    setUserAccessToken(state, payload){
      state.AccessToken = payload.AccessToken;
    },
    setAmerusBucksCredit(state, payload){
      state.AmerusBucksCredit = payload.AmerusBucksCredit;
    },
    setLabelOfEachSale(state, payload){
      state.LabelOfEachSale = payload.LabelOfEachSale;
    },
    setProductVariationPrice(state, payload){
      state.VariationProductPrice = 0;
      state.VariationProductPrice = payload.price.price
    },
    setAmerusBucks(state, payload){
      state.AmerusBucks = payload.AmerusBucksData
    },
    setPremium(state, payload){
      state.Premium = payload.Premium
    },

    setBonusPool(state, payload){
      state.BonusPool = payload.BonusPool
    },
    setTripBonus(state, payload){
      state.TripBonus = payload.TripBonus
    },
    setCarBonus(state, payload){
      state.CarBonus = payload.CarBonus
    },
    setAnnualAuction(state, payload){
      state.AnnualAuction = payload.AnnualAuction
    },
    setAnnualAuctionCredit(state, payload){
      state.AnnualAuctionCredit = payload.AnnualAuction
    },
    setPoints(state, payload){
      state.points = payload.Points
    },
    setSpins(state, payload){
      state.spins = payload.Spins
    },
    setModalState(state, payload){
      console.log(payload.isVisible);
      state.ModalState = payload.isVisible;
    },
    setRemoveCartPrice(state, payload){
      //state.TotalPrice.pop(payload.ProductToBeRemoved);
      for( var i = 0; i < state.Cart.length; i++){ 
        console.log("ammount: " + state.Cart[i]);                      
      if ( state.Cart[i].theProduct === payload.ProductToBeRemoved) { 
        state.Cart.splice(i, 1); 
          i--; 
      }
}
    },
    setRemoveCart(state, payload){
      //state.Cart.pop(payload.ProductToBeRemoved);
      for( var i = 0; i < state.Cart.length; i++){ 
            console.log(state.Cart[i].theProduct.name);                      
        if ( state.Cart[i].theProduct === payload.ProductToBeRemoved) { 
          state.Cart.splice(i, 1); 
            i--; 
        }
    }
    },
    setOrderPhone(state, payload){
      state.orderPhone = payload
    },
    setOrderEmail(state, payload){
      state.orderEmail = payload
    },
    setZip(state, payload){
      state.zip = payload
    },
    setState(state, payload){
      state.state = payload
    },
    setCity(state, payload){
      state.city = payload
    },
    setStreet(state, payload){
      state.street = payload
    },
    setUserFirstName(state, payload){
      state.firstName = payload
    },
    setUserLastName(state, payload){
      state.lastName = payload
    },
    setCart(state, payload){
      state.Cart.push(payload.Cart);
    },
    setProducts(state, payload){
      state.Products = payload.ProductData;
    },
    setLocation(state, payload){
      state.Location = payload.Location
    },
    setFacebook(state, payload){
      state.Facebook = payload.Url
    },
    setTwitter(state, payload){
      state.Twitter = payload.Url
    },
    setLinkedIn(state, payload){
      state.LinkedIn = payload.Url
    },
    setYouTube(state, payload){
      state.YouTube = payload.Url
    },
    setTotalOfEachSale(state, payload){
      state.TotalOfEachSale = payload.SalesData
    },
    setId(state, payload){
      state.userId = payload.Id
    },
    setUserName(state, payload){
      state.username = payload
    },
    setProfileImage(state, payload){
      state.ProfileImage = payload.ProfileImage
    },
    setPassword(state, payload){
      state.password = payload
    },
    resetPassword(state, payload){
      state.password = payload.password
    },
    setHasPermission(state, payload){
      state.HasPermission = payload.Permission
    },
    setError(state, payload){
      state.Error = payload.Error
    },
    setToken(state, payload){
      state.Token = payload.Token
    },
    setLogOut(state, payload){
      state.HasPermission = payload.Permission
    },
    setAmerusUniversity(state, payload){
      state.Posts = payload.AmerusUniversityData
    },
    setAgentSalesData(state, payload){
      state.TotalSales = payload.SalesData
    },
    setAgentType(state, payload){
      state.TheAgentType = payload.AgentType
    },
    setAgentData(state, payload){
      state.Agent = payload.agentData
    },
    setName(state, payload){
      state.Agent.name = payload.name;
    },
    setEmail(state, payload){
      state.Email = payload.Email;
    },
    setMobileNumber(state, payload){
      state.MobileNumber = payload.MobileNumber;
    },
    setStartDate(state, payload){
      state.StartDate = new Date(payload.agentStartDate).toISOString().split('T')[0];
    },
    resetStartDate(state, payload){
      state.StartDate = payload.agentStartDate;
    },
    setSalesSinceHire(state, payload){
      state.SalesSinceHire = payload.salesSinceHire;
    },
    setRevenueSinceHire(state, payload){
      state.RevenueSinceHire = payload.salesSinceHire;
    },
    setProgress(state, payload){
      state.Progress = payload.progress;
    },
    setNextRankDefault(state, payload){
        state.NextRank = payload.NextRank;
    },
    setNextRank(state){
      if( state.TheAgentType === 1 ){
          state.NextRank = 100000;
      }

      if( state.TheAgentType === 2 ){
        state.NextRank = 500000;
      }

      if( state.TheAgentType === 3 ){
        state.NextRank = 500000;
      }
    },
    setCurrentRank(state, payload){
      state.CurrentRank = payload.SumOfFilteredSales;
      },
  },
  actions:{
    async topEarners(){
      const headers = { "Content-Type": "application/json" };
      return fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/?per_page=100`, { headers })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
        })
    },
    async resetUserCarBonus({getters}, payload){
      // data to be sent to the POST request
      console.log("Resting Car Bonus" + payload);
      var total = payload
       let data = {
         "carbonus": total.toString(),
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
     },
    async updateCurrentQuarter({getters}, payload){
      // data to be sent to the POST request
      
      var currentQuarter = parseInt(payload)
       let data = {
         "currentquarter": currentQuarter.toString()
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
     },
    async setCurrentQuarter({getters, commit}){
      const headers = { "Content-Type": "application/json" };
      return fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { headers })
       .then((response) => response.json())
        .then((data) => {
          
          //add current year to vuex
          commit({
            type: 'setProfileQuarter',
            Quarter: data.currentquarter
          })

          //add current quarter to vuex
        })
     },
    async setCurrentYear({getters, commit}){
      const headers = { "Content-Type": "application/json" };
      return fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { headers })
       .then((response) => response.json())
        .then((data) => {
          
          //add current year to vuex
          commit({
            type: 'setProfileYear',
            Year: data.currentyear
          })

          //add current quarter to vuex
        })
     },
    async updateCurrentYear({getters}, payload){
      // data to be sent to the POST request
      
      var currentYear = parseInt(payload)
       let data = {
         "currentyear": currentYear.toString()
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
     },
    async updateUserCarBonus({getters}, payload){
      // data to be sent to the POST request
      
      var total = parseInt(getters.getCarBonus) + parseInt(payload)
       let data = {
         "carbonus": total.toString()
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
    },
    async updateUserTripBonus({getters}, payload){
      // data to be sent to the POST request
      
      var total = parseInt(getters.getTripBonus) + parseInt(payload)
       let data = {
         "tripbonus": total.toString()
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
    },
    async updateUserAnnualAuction({getters}, payload){
      // data to be sent to the POST request
      
      var total = parseInt(getters.getAnnualAuction) + parseInt(payload)
       let data = {
         "annualauction": total.toString()
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
     },
    async updaterUserPremium({getters}, payload){
     // data to be sent to the POST request
     
     var total = parseInt(getters.getPremium) + parseInt(payload)
      let data = {
        "premium": total.toString()
      }
      var token = getters.getToken;
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
        method: "POST",
        body: JSON.stringify(data),
        headers: myHeaders 
      })
    },
    async resetUserPremiums({getters}, payload){
      // data to be sent to the POST request
      console.log("Resting APV's" + payload);
      var total = payload
       let data = {
         "premium": total.toString(),
         "annualauction": total.toString(),
         "tripbonus": total.toString(),
       }
       var token = getters.getToken;
       var myHeaders = new Headers();
       myHeaders.append("Authorization", `Bearer ${token}`);
       myHeaders.append("Content-Type", "application/json");
       return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { 
         method: "POST",
         body: JSON.stringify(data),
         headers: myHeaders 
       })
     },
    userAccessToken({commit}, payload){
      commit({
        type: 'setUserAccessToken',
        AccessToken: payload
      })
    },
    async updateAmerusBucks({getters}, payload){
      const api = new WooCommerceRestApi({
        url: "https://amerusfinancialgroup.com",
        consumerKey: "ck_b059efade41d7577f9fc396e8f79491a6efa4979",
        consumerSecret: "cs_c102547b2f590c04fe114c0c9e460cd42950f408",
        // consumerKey: "ck_3055af7d8246b24e1caf9ab7a1a2326e5b956e55",
        // consumerSecret: "cs_7395531200592e2239523712496fd413e42a2e2d",
        version: "wc/v3",
        queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
      });
      console.log("Email: " + getters.getEmail);
      // update wallet
      await api.post("wallet", {
        email:getters.getEmail,
        type: "credit",
        amount: payload,
      })
    .then((response) => {
      // Successful request
      console.log("Wallet Response Status:", response.status);
      console.log("Wallet Response Headers:", response.headers);
      console.log("Wallet Response Data:", response.data);
    })
    .catch((error) => {
      // Invalid request, for 4xx and 5xx statuses
      console.log("Response Status:", error.response.status);
      console.log("Response Headerssss:", error.response.headers);
      console.log("Response Dataaaaa:", error.response.data);
    })
    .finally(() => {
      // Always executed.
    });
    },
    async updateGoogleSheets({getters},rowIndex){
      //why I need to have first param as object?
      var token = getters.getUserAccessToken;
      console.log("updateGoogleSheetszzzz: " + getters.AgentType +  rowIndex);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);
      myHeaders.append("Content-Type", "application/json");
      // need to make the range dynamic. get row number of current row
      var inx = rowIndex;
      var raw = `{
        valueInputOption: 'USER_ENTERED',
        data:[
          {
            range: 'AgentData!A${inx}',
            majorDimension: 'ROWS',
            values: [["TRUE"]]}
        ]}`;
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:  raw,
      };
  
 await fetch("https://sheets.googleapis.com/v4/spreadsheets/1Y8dUcQZXFvDz2vn7l97V2k2iFGY5PBrtoFffR5AzEoM/values:batchUpdate", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
  
    },
    async googleSheetsData({dispatch, commit, getters}){
      var requestOptions = {
        method: 'GET',
        redirect: 'follow'
      };
      
     await fetch("https://sheets.googleapis.com/v4/spreadsheets/1Y8dUcQZXFvDz2vn7l97V2k2iFGY5PBrtoFffR5AzEoM/values/A2:CM100/?key=AIzaSyA9hqfvNGEXQlJDFuN_JD0Yq7oB-Yk_iEA", requestOptions)
      .then(response => response.json())
      .then(result =>{
        var payload = 0; 
        var totalPayload = 0;  
        var totalPremium = 0; 
        var totalUserAnnualAuction = 0; 
        var totalUserTripBonus = 0; 
        var totalUserCarBonus = 0;
        var rowIndex = 0;
        var cutOffDate = new Date(Date.parse("04/20/2022"));
        console.log("cutOffDate: " + cutOffDate)
        // const isCutOffDate = (someDate) => {
        //   return someDate.getDate() >= cutOffDate.getDate() &&
        //     someDate.getMonth() >= cutOffDate.getMonth() &&
        //     someDate.getFullYear() >= cutOffDate.getFullYear()
        // }
         result.values.forEach(row => {
          console.log("DATE: " + new Date(row[2]));
          //console.log(getters.getName);
          //(row.c[0].v == false) && (row.c[2].v === this.getters.getName)

          // Start Processing
          var meetingTime = new Date();
          meetingTime.setHours(14,15,0,0);
          console.log("meetingTime: " + meetingTime.getTime());

          // Current Time
          var now = new Date().getTime(); // 1501653935994
          console.log("now: " + now);
        
          if( ((row[0] != "TRUE") ) && (row[3] === getters.getName) && ( now >= meetingTime.getTime() )  ){
            // console.log(row.c[67].v);
            console.log(row);
            payload += parseInt(row[86]);
            totalPayload += parseInt(row[86]);
            totalPremium += parseInt(row[82]);
            totalUserAnnualAuction += parseInt(row[87]);
            totalUserTripBonus += parseInt(row[89]);
            totalUserCarBonus += parseInt(row[88]);
   

            rowIndex = row[1];

            new Promise((resolve) => {
              dispatch(
                "updateGoogleSheets",
                rowIndex
              )
              //This calculates the Amerus Bucks Credit
                .then((response) => {
                  try {
                    console.log("updateAmerusBucks Dispatched")
                    commit({
                      type: 'setAmerusBucksCredit',
                      AmerusBucksCredit: totalPayload
                    })
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Premium
                .then((response) => {
                  try {
                    //console.log("updateAmerusBucks Dispatched")
                    commit({
                      type: 'setUserPremium',
                      Premium: Number(totalPremium)
                    })
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the AnnualAuction Credit
                .then((response) => {
                  try {
                    //console.log("updateAmerusBucks Dispatched")
                    commit({
                      type: 'setUserAnnualAuction',
                      AnnualAuction: Number(totalUserAnnualAuction)
                    })
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })
                
                //This calculates the APV
                .then((response) => {
                  try {
                    //console.log("updateAmerusBucks Dispatched")
                    dispatch(
                      "updaterUserPremium",Number(totalPremium)
                    )
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Annual Auction
                .then((response) => {
                  try {
                    //console.log("updateAmerusBucks Dispatched")
                    dispatch(
                      "updateUserAnnualAuction",Number(totalUserAnnualAuction)
                    )
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Trip Bonus
                .then((response) => {
                  try {
                    dispatch(
                      "updateUserTripBonus",Number(totalUserTripBonus)
                    )
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Trip Bonus Credit
                .then((response) => {
                  try {
                    commit({
                      type: 'setUserTripBonusCredit',
                      setUserTripBonusCredit: Number(totalUserTripBonus)
                    })
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Car Bonus
                .then((response) => {
                  try {
                    dispatch(
                      "updateUserCarBonus",Number(totalUserCarBonus)
                    )
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

                //This calculates the Car Bonus Credit
                .then((response) => {
                  try {
                    commit({
                      type: 'setUserCarBonusCredit',
                      setUserCarBonusCredit: Number(totalUserTripBonus)
                    })
                  } catch (error) {
                    console.error("ERROR: " + error);
                  }
                  resolve(response); // return response data to calling function
                })

            });


          }
        });
        new Promise((resolve) => {
          dispatch(
            "updateAmerusBucks",
            payload
          )
            .then((response) => {
              try {
                console.log("updateAmerusBucks Dispatched")
                commit({
                  type: 'setAmerusBucksCredit',
                  AmerusBucksCredit: totalPayload
                })
              } catch (error) {
                console.error("ERROR: " + error);
              }
              resolve(response); // return response data to calling function
            })
        });
      })
      .catch(error => console.log('error', error));
    },
  
    async loadAgentAmerusBucks({commit,getters}){
    const api = new WooCommerceRestApi({
      url: "https://amerusfinancialgroup.com",
      consumerKey: "ck_b059efade41d7577f9fc396e8f79491a6efa4979",
      consumerSecret: "cs_c102547b2f590c04fe114c0c9e460cd42950f408",
      // consumerKey: "ck_3055af7d8246b24e1caf9ab7a1a2326e5b956e55",
      // consumerSecret: "cs_7395531200592e2239523712496fd413e42a2e2d",
      version: "wc/v3",
      queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
    });
    // List products
   await api.get("wallet/balance", {
      email:getters.getUserName,
    })
  .then((response) => {
    // Successful request
    console.log("Woo Response Status:", response.status);
    console.log("Woo Response Headers:", response.headers);
    console.log("Woo Response Data:", response.data);
    console.log("Woo Total of pages:", response.headers['x-wp-totalpages']);
    console.log("Woo Total of items:", response.headers['x-wp-total']);
    console.log("BALANCE: " + response.data.balance);
    commit({
      type: 'setAmerusBucks',
      AmerusBucksData: parseInt(response.data.balance)
    })
  })
  .catch((error) => {
    // Invalid request, for 4xx and 5xx statuses
    console.log("Response Status:", error.response.status);
    console.log("Response Headers:", error.response.headers);
    console.log("Response Data:", error.response.data);
  })
  .finally(() => {
    // Always executed.
  });
    },



    logError({commit}){
      commit({
        type: 'setError',
        Error: "Please Try Again"
      })
    },
    resetLogError({commit}){
      commit({
        type: 'setError',
        Error: ""
      })
    },
    productVariationPrice({commit}, price){
      commit({
        type: 'setProductVariationPrice',
        price
      })
    },
    updateOrder(){
      const api = new WooCommerceRestApi({
        url: "https://amerusfinancialgroup.com/",
        consumerKey: "ck_b059efade41d7577f9fc396e8f79491a6efa4979",
        consumerSecret: "cs_c102547b2f590c04fe114c0c9e460cd42950f408",
        // consumerKey: "ck_3055af7d8246b24e1caf9ab7a1a2326e5b956e55",
        // consumerSecret: "cs_7395531200592e2239523712496fd413e42a2e2d",
        version: "wc/v3",
        queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
      });
      api.put(`orders/338`, {
        status: "completed"
      })
    },
   async createOrder({getters}){
       const api = new WooCommerceRestApi({
        url: "https://amerusfinancialgroup.com/",
        consumerKey: "ck_b059efade41d7577f9fc396e8f79491a6efa4979",
        consumerSecret: "cs_c102547b2f590c04fe114c0c9e460cd42950f408",
        // consumerKey: "ck_3055af7d8246b24e1caf9ab7a1a2326e5b956e55",
        // consumerSecret: "cs_7395531200592e2239523712496fd413e42a2e2d",
        version: "wc/v3",
        queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
      });

      var line_items = [];
      for (let i = 0; i < getters.getCart.length; i++) {
        line_items[i]={
          product_id:getters.getCart[i].theProduct.id
        }
       }
      // Make an order
      api.post("orders", {
        status: "completed",
          payment_method: "Credit Card",
          payment_method_title: "Credit Card",
          set_paid: true,
          billing: {
            first_name: getters.getFirstName,
            last_name: getters.getLastName,
            address_1: getters.getStreet,
            address_2: "",
            city: getters.getCity,
            state: getters.getState,
            postcode: getters.getZip,
            country: "US",
            email: getters.getOrderEmail,
            phone:  getters.getOrderPhone
          },
          shipping: {
            first_name: getters.getFirstName,
            last_name: getters.getLastName,
            address_1: getters.getStreet,
            address_2: "",
            city: getters.getCity,
            state: getters.getState,
            postcode: getters.getZip,
            country: "US"
          },
          line_items: line_items,
          shipping_lines: [
            {
              method_id: "flat_rate",
              method_title: "Flat Rate",
              total: "10.00"
            }
          ]
        
      })
      // Tutorial https://dev.to/hudaniabhee/payment-handling-with-stripe-node-and-express-4gh0
      //this 'then' will be used to make a one time payment to strip
      .then((data) => {
        console.log(data);
      })
      //this 'then' will be used to update the order from pending to completed
      .then((data) => {
        console.log(data);
      })
    },
    closeModal({commit}){
      commit({
        type: 'setModalState',
        isVisible: false
      })
    },
    async userToken({commit, getters}){
      //const token = 'https://amerusfinancialgroup.com/wp-json/jwt-auth/v1/token';
       await fetch(`https://amerusfinancialgroup.com/wp-json/jwt-auth/v1/token?username=${getters.getUserName}&password=${getters.getPassword}`,{
        method: 'POST'
       })
      .then((response) => response.json())
        .then((data) => {
          console.log(data.token);
          if(data.token){
            commit({
              type: 'setToken',
              Token: data.token
            })
            sessionStorage.setItem('JWT', data.token)
          }
        })
      },
    async userLogin({commit, getters, dispatch}){
     await dispatch("userToken");
      return fetch(`https://amerusfinancialgroup.com/wp-json/jwt-auth/v1/token?username=${getters.getUserName}&password=${getters.getPassword}`, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc
         
      })
        .then((response) => response.json())
        .then((data) => {
          if(data.token){
            commit({
              type: 'setHasPermission',
              Permission: true
            })
          }
          if(data.user_id){
            commit({
              type: 'setId',
              Id: data.user_id
            })
          }
        
        });

      
    },
    logOut({commit}){
      commit({type: 'setName',name: ''}),
      commit({type: 'setProfileImage',ProfileImage: 'https://amerusfinancialgroup.com/wp-content/uploads/2022/02/default.jpg'}),
      commit({type: 'setLogOut', Permission: false}),
      commit({type: 'setToken',Token: ""}),
      commit({type: 'resetPassword',password: ""}),
      commit({type: 'setId',Id: ""}),
      commit({type: 'setAgentData',agentData:[]}),
      commit({type: 'setAmerusBucks',AmerusBucksData:0}),
      commit({type: 'setAmerusBucksCredit',AmerusBucksCredit:0}),
      commit({type: 'setAnnualAuction',AnnualAuction:0}),
      commit({type: 'setAnnualAuctionCredit',AnnualAuction:0}),
      commit({type: 'setBonusPool',BonusPool:""}),
      commit({type: 'setCarBonus',CarBonus:""}),
      commit({type: 'setUserCarBonusCredit',setUserCarBonusCredit:0}),
      commit({type: 'setCurrentRank',SumOfFilteredSales:0}),
      commit({type: 'setNextRankDefault',NextRank:0}),
      commit({type: 'setAgentType',AgentType:0}),
      commit({type: 'setEmail',Email:""}),
      commit({type: 'setFacebook',Url:""}),
      commit({type: 'setTwitter',Url:""}),
      commit({type: 'setYouTube',Url:""}),
      commit({type: 'setLinkedIn',Url:""}),
      commit({type: 'setLocation',Location:""}),
      commit({type: 'setMobileNumber',MobileNumber:""}),
      commit({type: 'setAmerusUniversity',AmerusUniversityData:[]}),
      commit({type: 'setPremium',Premium:""}),
      commit({type: 'setUserPremium',Premium:0}),
      commit({type: 'setProducts',ProductData:[]}),
      commit({type: 'setProgress',progress:0}),
      commit({type: 'resetStartDate',agentStartDate:""}),
      commit({type: 'setTripBonus',TripBonus:0}),
      commit({type: 'setUserTripBonusCredit',setUserTripBonusCredit:0}),
      commit({type: 'setError',Error:""}),
      commit({type: 'setProfileYear',Year:""}),
      

      // commit({type: 'setLabelOfEachSale',LabelOfEachSale:[]}),

      document.body.classList.remove("hero-bg");
      router.push('/')
      window.sessionStorage.removeItem('vuex');
      window.sessionStorage.removeItem('JWT');
    },
     loadAgentData({commit,getters,state}){
      const headers = { "Content-Type": "application/json" };
      return fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/users/${getters.getUserId}`, { headers })
        .then((response) => response.json())
        .then((data) => {
          var startDate = new Date(data.StartDate).toISOString().split('T')[0]
          var profileImage = data.avatar_urls[96]
          var Email = data.user_email
          var MobileNumber = data.usermobile
          var FacebookUrl = data.facebook
          var TwitterUrl = data.twitter
          var LinkedInUrl = data.linkedin
          var YoutubeUrl = data.youtube
          var location = data.location
          var points = data.points
          var spins = data.spins
          var premium = Number(data.premium)

          var annualauction = data.annualauction
          var carbonus = data.carbonus
          var tripbonus = data.tripbonus
          var bonuspool = data.bonuspool

          
         
          


          commit({
            type: 'setAgentData',
            agentData: data 
          }),
          commit({
            type: 'setStartDate',
            agentStartDate: startDate
            
          }),
          commit({
            type: 'setProfileImage',
            ProfileImage: profileImage
          }),
          commit({
            type: 'setEmail',
            Email: Email
          }),
          commit({
            type: 'setMobileNumber',
            MobileNumber: MobileNumber
          }),
          commit({
            type: 'setFacebook',
            Url: FacebookUrl
          }),
          commit({
            type: 'setTwitter',
            Url: TwitterUrl
          }),
          commit({
            type: 'setLinkedIn',
            Url: LinkedInUrl
          }),
          commit({
            type: 'setYouTube',
            Url: YoutubeUrl
          }),
          commit({
            type: 'setLocation',
            Location: location
          }),
          commit({
            type: 'setPoints',
            Points: points
          }),
          commit({
            type: 'setSpins',
            Spins: spins
          }),
          commit({
            type: 'setPremium',
            Premium: premium
          }),
          commit({
            type: 'setAnnualAuction',
            AnnualAuction: annualauction
          }),
          commit({
            type: 'setCarBonus',
            CarBonus: carbonus
          }),
          commit({
            type: 'setTripBonus',
            TripBonus: tripbonus
          }),
          commit({
            type: 'setBonusPool',
            BonusPool: bonuspool
          })
        })
        .then(() => {
          const headers = { 'Content-Type': 'application/json',
      'Accept': 'application/json' };
      return  fetch('https://amerusfinancialgroup.com/wp-json/wp/v2/sales/', { headers })
        .then((response) => response.json())
        .then((data) => {
         
        var agentSales = data;
        
        const totalSalesArray = [];
        
        agentSales.filter(function(singleSale){
          if((new Date(singleSale.date) > new Date(state.StartDate))  &&  (singleSale.author === state.userId)){
            
            totalSalesArray.push(parseInt(singleSale.meta.sales_options_salestotal));
          }
        })
        
        var sumOfFilteredSales = getters.getNextRank
        // for (let i = 0; i < totalSalesArray.length; i++){
        //   sumOfFilteredSales += parseInt(totalSalesArray[i])
        // }
        //set the agent type here
        var agentType = 0;
        if((sumOfFilteredSales <= 100000) && (sumOfFilteredSales >= 299999) && agentType === 0){
          agentType = 1;
        }else if((sumOfFilteredSales <= 1000000) && (sumOfFilteredSales >= 300001) ){
          agentType = 3;
        }else{
          agentType = 2;
        }
          commit({
            type: 'setTotalOfEachSale',
            SalesData: totalSalesArray
          }),
          commit({
            type: 'setSalesSinceHire',
            salesSinceHire: parseInt(totalSalesArray.length)
          }),
          commit({
            type: 'setRevenueSinceHire',
            salesSinceHire: sumOfFilteredSales
          }),
          commit({
            type: 'setAgentType',
            AgentType: agentType
          }),
          commit({
            type: 'setNextRank',
            AgentType: agentType
          }),
          commit({
            type: 'setCurrentRank',
            SumOfFilteredSales: sumOfFilteredSales
          })
          
          var progress = 0;
        
          progress = (getters.getPremium / getters.getNextRank) * 100;
          console.log("Premium: " + getters.getPremium);
          console.log("NextRank: " + getters.getNextRank);
          console.log("progress: " + progress);
          commit({
            type: 'setProgress',
            progress: progress
          })
          //console.log("THE TOTAL: " + total);
        })
        })
    },
    
    // http://demo.wp-api.org/wp-json/wp/v2/posts?include[]=470&include[]=469
    async AmerusJournal({commit, getters}){
      var user = getters.getUserId;
      const headers = { "Content-Type": "application/json" };
      return await fetch(`https://amerusfinancialgroup.com/wp-json/wp/v2/posts/?_embed&per_page=100&author=${user}`, { headers })
        .then((response) => response.json())
        .then((data) => {
          commit({
            type: 'setAmerusUniversity',
            AmerusUniversityData: data
          })
        })
    },



    amerusProducts({commit}){
    const api = new WooCommerceRestApi({
      url: "https://amerusfinancialgroup.com/",
      consumerKey: "ck_b059efade41d7577f9fc396e8f79491a6efa4979",
        consumerSecret: "cs_c102547b2f590c04fe114c0c9e460cd42950f408",
      // consumerKey: "ck_3055af7d8246b24e1caf9ab7a1a2326e5b956e55",
      // consumerSecret: "cs_7395531200592e2239523712496fd413e42a2e2d",
      version: "wc/v3",
      queryStringAuth: true // Force Basic Authentication as query string true and using under HTTPS
    });
    // List products
    api.get("products", {
      per_page: 20, // 20 products per page
      status: 'publish'
    })
  .then((response) => {
    // Successful request
    console.log("Woo Response Status:", response.status);
    console.log("Woo Response Headers:", response.headers);
    console.log("Woo Response Data:", response.data);
    console.log("Woo Total of pages:", response.headers['x-wp-totalpages']);
    console.log("Woo Total of items:", response.headers['x-wp-total']);
    commit({
      type: 'setProducts',
      ProductData: response.data
    })
  })
  .catch((error) => {
    // Invalid request, for 4xx and 5xx statuses
    console.log("Response Status:", error.response.status);
    console.log("Response Headers:", error.response.headers);
    console.log("Response Data:", error.response.data);
  })
  .finally(() => {
    // Always executed.
  });

// Create a product
// api.post("products", {
//   name: "Premium Quality", // See more in https://woocommerce.github.io/woocommerce-rest-api-docs/#product-properties
//   type: "simple",
//   regular_price: "21.99",
// })
//   .then((response) => {
//     // Successful request
//     console.log("Response Status:", response.status);
//     console.log("Response Headers:", response.headers);
//     console.log("Response Data:", response.data);
//   })
//   .catch((error) => {
//     // Invalid request, for 4xx and 5xx statuses
//     console.log("Response Status:", error.response.status);
//     console.log("Response Headers:", error.response.headers);
//     console.log("Response Data:", error.response.data);
//   })
//   .finally(() => {
//     // Always executed.
//   });

// // Edit a product
// api.put("products/1", {
//   sale_price: "11.99", // See more in https://woocommerce.github.io/woocommerce-rest-api-docs/#product-properties
// })
//   .then((response) => {
//     // Successful request
//     console.log("Response Status:", response.status);
//     console.log("Response Headers:", response.headers);
//     console.log("Response Data:", response.data);
//   })
//   .catch((error) => {
//     // Invalid request, for 4xx and 5xx statuses
//     console.log("Response Status:", error.response.status);
//     console.log("Response Headers:", error.response.headers);
//     console.log("Response Data:", error.response.data);
//   })
//   .finally(() => {
//     // Always executed.
//   });

// // Delete a product
// api.delete("products/1", {
//   force: true, // Forces to delete instead of move to the Trash
// })
//   .then((response) => {
//     // Successful request
//     console.log("Response Status:", response.status);
//     console.log("Response Headers:", response.headers);
//     console.log("Response Data:", response.data);
//   })
//   .catch((error) => {
//     // Invalid request, for 4xx and 5xx statuses
//     console.log("Response Status:", error.response.status);
//     console.log("Response Headers:", error.response.headers);
//     console.log("Response Data:", error.response.data);
//   })
//   .finally(() => {
//     // Always executed.
//   });
 
       
     },
     addToCart({commit}, theProduct){
       console.log("Cart: " + theProduct);
      
      commit({
        type: 'setCart',
        Cart: theProduct
      })
     },
     removeFromCart({commit},theProduct){
       console.log("theProduct: " + theProduct.theProduct.name);
       commit({
        type: 'setRemoveCart',
        ProductToBeRemoved: theProduct.theProduct
      })
      commit({
        type: 'setRemoveCartPrice',
        ProductToBeRemoved: theProduct.theProduct
      })
     },
  },
  //using session storage so the data is deleted when the tab or window is closed
  plugins: [createPersistedState({
    storage: window.sessionStorage,
})],
})

