<template>
  <div id="loading" class="align-self-center ml-auto mr-auto">
    <img class="img" src="../assets/spinner.gif" alt="loading..." width="200" />
    <h1 class="text-light">Loading...</h1>
    <h3 class="text-light">Please Be Patient, We're Gathering Your Data</h3>
  </div>
</template>

<script>
export default {
  name: "Loading",
  methods: {
    fetchData() {
      // this.$store.dispatch("loadAgentData");

      // this.$store.dispatch("amerusProducts");
      // this.$store.dispatch("AmerusJournal");
      // this.$store.dispatch("loadAgentAmerusBucks");
      // this.$store.dispatch("googleSheetsData");

      new Promise((resolve, reject) => {
        this.$store
          .dispatch("loadAgentData")
          .then((loadAgentDataResponse) => {
            // request succeeded
            try {
              this.$store.dispatch("googleSheetsData");
            } catch (error) {
              console.error("ERROR: " + error);
            }
            //process this once a week

            resolve(loadAgentDataResponse); // return response data to calling function
          })
          .then((amerusProductsResponse) => {
            // request succeeded
            try {
              this.$store.dispatch("amerusProducts");
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(amerusProductsResponse); // return response data to calling function
          })
          .then((AmerusJournalResponse) => {
            // request succeeded
            try {
              this.$store.dispatch("AmerusJournal");
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(AmerusJournalResponse); // return response data to calling function
          })
          .then((loadAgentAmerusBucksResponse) => {
            // request succeeded
            try {
              this.loadAgentAmerusBucks;
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(loadAgentAmerusBucksResponse); // return response data to calling function
          })
          .catch((error) => {
            // request failed
            console.log("Please Try Again");

            this.$store.dispatch("logError");
            reject(error); // return error to calling function
          });
      });
    },
  },

  mounted() {
    this.fetchData();
  },
  computed: {
    loadAgentAmerusBucks() {
      return this.$store.dispatch("loadAgentAmerusBucks");
    },
  },
};
</script>