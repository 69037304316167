<template>
  <div id="content" class="demo col pt-0">
    <navbar />
    <Welcome />
    <div class="row-fluid">
      <div class="card-deck">
        <!-- <div class="card">
          <div class="card-body">
            <h5 class="card-title">Total Sales</h5>
            <BarChart
              element="BarChart0"
              ChartBackgroundColor="#3BB300"
              :ChartDataSet="totalOfEachSale"
              :ChartLabels="labelOfEachSale"
              :key="totalOfEachSale"
            />
          </div>
        </div> -->
        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Total Revenue</h5>
              <!-- <MultiLineChart
                element="MultiLineChart2"
                ChartBackgroundColor1="rgba(255,0,0,0.1)"
                ChartBackgroundColor2="rgb(255, 145, 36, 0.1)"
                ChartBorderColor1="#9e5d35"
                ChartBorderColor2="#FFC233"
                :ChartLabels="[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]"
                :SalesData="totalOfEachSale"
                :RevenueData="[17000, 14200, 21300, 51500, 95400]"
              /> -->
              <LineChart
                element="LineChart2"
                ChartBackgroundColor="rgb(162, 127, 249 , 0.1)"
                ChartBorderColor="#8B6DD6"
                :ChartLabels="[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]"
                DatasetLabel="Sales Per Month"
                :ChartData="[
                  8300, 7030, 10060, 12030, 2000, 6007, 3050, 1200, 8200, 9800,
                  4500, 12340,
                ]"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Last Month Revenue</h5>
              <LineChart
                element="LineChart16"
                ChartBackgroundColor="rgb(162, 127, 249 , 0.1)"
                ChartBorderColor="#8B6DD6"
                :ChartLabels="[
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ]"
                DatasetLabel="Revenue"
                :ChartData="[8, 7, 10, 12, 2, 6, 3]"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Last Month Sales</h5>
              <LineChart
                element="LineChart1"
                ChartBackgroundColor="rgb(16, 239, 209, 0.1)"
                ChartBorderColor="#10efd1"
                DatasetLabel="Sales"
                :ChartLabels="[
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ]"
                :ChartData="[8, 7, 10, 12, 2, 6, 3]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row-fluid mt-4">
      <div class="card-deck">
        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Bonus Ranking</h5>
              <MultiSeriesPieChart element="MultiSeriesPieChart2" />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">
                <h5 class="card-title">Amerus Bucks</h5>
              </h5>
              <BarChart
                element="BarChart1"
                ChartBackgroundColor="#67e20f"
                :ChartLabels="[
                  'January',
                  'February',
                  'March',
                  'April',
                  'May',
                  'June',
                  'July',
                  'August',
                  'September',
                  'October',
                  'November',
                  'December',
                ]"
                :ChartDataSet="[
                  5000, 10000, 15000, 20000, 25000, 30000, 35000, 40000, 45000,
                  50000, 55000, 60000,
                ]"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-xl-4 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Calls Made Today</h5>
              <LineChart
                element="LineChart22"
                ChartBackgroundColor="rgb(213, 247, 213, 0.1)"
                ChartBorderColor="#d5f7d5"
                :ChartLabels="[
                  '12am',
                  '1am',
                  '2am',
                  '3am',
                  '4am',
                  '5am',
                  '6am',
                  '7am',
                  '8am',
                  '9am',
                  '10am',
                  '11am',
                  '12pm',
                  '1pm',
                  '2pm',
                  '3pm',
                  '4pm',
                  '5pm',
                  '6pm',
                  '7pm',
                  '8pm',
                  '9pm',
                  '10pm',
                  '11pm',
                ]"
                DatasetLabel="Calls"
                :ChartData="[
                  8, 7, 10, 12, 2, 6, 3, 1, 9, 2, 11, 2, 4, 2, 5, 11, 3, 5, 9,
                  11, 1, 2, 13, 1,
                ]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row-fluid mt-4">
      <div class="card-deck">
        <div class="col-lg-12 col-xl-6 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Last Weeks Sales</h5>
              <MultiLineChart
                element="MultiLineChart1"
                ChartBackgroundColor="#ffcd56"
                ChartBorderColor="#a0791c"
                :ChartLabels="[
                  'Sunday',
                  'Monday',
                  'Tuesday',
                  'Wednesday',
                  'Thursday',
                  'Friday',
                  'Saturday',
                ]"
                :ChartData="[8, 7, 10, 12, 2, 6, 3]"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-12 col-xl-6 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Last Quarter Sales</h5>
              <MultiLineChart
                element="MultiLineChart3"
                ChartBackgroundColor="#ff5656"
                ChartBorderColor="#8e3030"
                :ChartLabels="['January', 'February', 'March']"
                :ChartData="[285, 231, 324]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row-fluid mt-4">
      <div class="card-deck">
        <div class="col-lg-12 col-xl-6 p-0">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Yesterday's Sales</h5>
              <BarChart
                element="BarChart1"
                ChartBackgroundColor="#ff6384"
                :ChartLabels="['1/27/2021']"
                :ChartData="[8]"
              />
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <a @click="$router.go(-1)" class="btn btn-primary mt-4"
      ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
    >
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "./Welcome";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import MultiSeriesPieChart from "../components/MultiSeriesPieChart.vue";

export default {
  components: {
    Navbar,
    Welcome,
    LineChart,
    BarChart,
    MultiSeriesPieChart,
  },
  computed: {
    totalOfEachSale() {
      return this.$store.getters.getTotalOfEachSale;
    },
    labelOfEachSale() {
      return this.$store.getters.getLabelOfEachSale;
    },
  },
  mounted() {
    // this.$store.dispatch("loadAgentSalesData");
  },
};
</script>

<style scoped>
.card {
  /* background-color: #2A2A2A; */
  background: #2a2a2a;
}
.card-title {
  color: #afafb4;
}
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.fa-calendar-alt,
.fa-graduation-cap,
.fa-building,
.fa-money-bill-alt,
.fa-medal,
.fa-file-invoice,
.fa-funnel-dollar {
  font-size: 7em;
  color: #ffffff;
}
.nav-link {
  color: #fff;
}
</style>