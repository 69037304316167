<template>
  <!-- <div id="content" class="col-xl-10 col-md-12"> -->
  <div id="content" class="demo col pt-0">
    <!-- <button type="button" class="btn" @click="showModal">Open Modal!</button> -->
    <!-- <Modal v-show="isModalVisible" @close="closeModal" /> -->
    <navbar />
    <Welcome />

    <div class="row-fluid graphite-gradient p-3 mt-5 mb-5">
      <p class="nav-link link-dark text-left pl-0 mb-0 h2">For You</p>
      <carousel :items-to-show="3" :breakpoints="breakpoints">
        <slide key="1">
          <!-- <router-link to="/leaderboard/" class="img-fluid lg-link w-100 mr-2"> -->
          <router-link to="/dashboard" class="img-fluid lg-link w-100 mr-2">
            <div class="card w-100">
              <img src="../assets/leaderboard.png" class="img-fluid" />
              <!-- <div class="card-header pb-0">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow text-left
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  LeaderBoard
                </h5>
                <p class="white text-shadow text-left">See How You Rank</p>
              </div> -->
              <!-- <div class="card-body d-flex pt-0 pb-0">
                <div class="align-self-center">
                  <div class="row align-base">
                    <div class="col-1">
                      <img
                        src="../assets/RobArchiveHover.jpg"
                        class="round"
                        width="30"
                      />
                    </div>
                    <div class="col-11">
                      <div class="leader-board">
                        <h5
                          class="
                            text-light
                            d-flex
                            align-items-center align-self-center
                            pl-2
                            pr-2
                            small
                          "
                        >
                          Rob Skinner
                          <span class="float-right">200 Sales</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="row align-base">
                    <div class="col-1">
                      <img
                        src="../assets/RickyArchiveHovers.jpg"
                        class="round"
                        width="30"
                      />
                    </div>
                    <div class="col-11">
                      <div class="leader-board">
                        <h5
                          class="
                            text-light
                            d-flex
                            align-items-center align-self-center
                            pl-2
                            pr-2
                            small
                          "
                        >
                          Ricky Verduzco
                          <span class="float-right">150 Sales</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div class="row align-base">
                    <div class="col-1">
                      <img
                        src="../assets/NickArchiveHovers.jpg"
                        class="round"
                        width="30"
                      />
                    </div>
                    <div class="col-11">
                      <div class="leader-board">
                        <h5
                          class="
                            text-light
                            d-flex
                            align-items-center align-self-center
                            pl-2
                            pr-2
                            small
                          "
                        >
                          Nick Potter
                          <span class="float-right">100 Sales</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="far fa-calendar-alt white"></i> See Current Rankings
                </p>
              </div> -->
            </div>
          </router-link>
        </slide>
        <slide key="2">
          <!-- <router-link to="/leaderboard/" class="img-fluid lg-link w-100 mr-2"> -->
          <!-- <router-link to="/dashboard" class="img-fluid lg-link w-100 mr-2"> -->
          <div class="card w-100 mr-2">
            <img src="../assets/amerus-bucks.png" class="img-fluid" />
            <!-- <div class="card-header pb-0">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow text-left
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  LeaderBoard
                </h5>
                <p class="white text-shadow text-left">See How You Rank</p>
              </div> -->
            <div class="card-body center-over-image">
              <p class="display-1">
                ${{ new Intl.NumberFormat().format(amerusBucks) }}
              </p>
            </div>
            <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="far fa-calendar-alt white"></i> See Current Rankings
                </p>
              </div> -->
          </div>
          <!-- </router-link> -->
        </slide>
        <slide key="3">
          <!-- <router-link to="/leaderboard/" class="img-fluid lg-link w-100 mr-2"> -->
          <!-- <router-link to="/dashboard" class="img-fluid lg-link w-100 mr-2"> -->
          <a
            href="https://amerusfinancialgroup.com/recognition/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100 mr-2">
              <img src="../assets/recog.png" class="img-fluid" />
              <!-- <div class="card-header pb-0">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow text-left
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  LeaderBoard
                </h5>
                <p class="white text-shadow text-left">See How You Rank</p>
              </div> -->
              <div class="card-body center-over-image">
                <p class="display-1 mb-0">
                  {{ recog }}
                </p>
                <p class="display-4 mb-0">to go until</p>
              </div>
              <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="far fa-calendar-alt white"></i> See Current Rankings
                </p>
              </div> -->
            </div>
          </a>
          <!-- </router-link> -->
        </slide>
        <slide key="4">
          <a
            href="http://amerusfinancialgroup.com/comp-plan-2022/"
            target="_blank"
            class="mr-2 w-100"
          >
            <div class="card w-100 mr-2">
              <img src="../assets/comp.png" class="img-fluid" />
              <!-- <div class="card-header">
              <h5
                class="
                  card-title
                  d-flex
                  align-items-center align-self-center
                  pb-0
                  mb-0
                "
              >
                Annual Auction
                <i class="far fa-money-bill-alt float-right"></i>
              </h5>
            </div> -->
              <!-- <div class="card-body d-flex"></div> -->
              <!-- <div class="card-footer">
              <router-link class="nav-link link-light" to="/CompensationBonuses"
                >View Bonuses</router-link
              >
            </div> -->
            </div>
          </a>
        </slide>
        <slide key="5">
          <a
            href="https://amerusfinancialgroup.com/agent-login/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/amerus-store.png" class="img-fluid" />
              <!-- <div class="card-header">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow
                    d-flex
                    align-items-center align-self-center
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  Amerus Store
                </h5>
                <p class="white text-shadow text-left">
                  Amerus Financial's Products
                </p>
              </div> -->
              <!-- <div class="card-body">
                <div class="row">
                  <div class="col-4 pr-0">
                    <p class="text-light right-border small mb-0">Leads</p>
                  </div>
                  <div class="col-4">
                    <p class="text-light right-border small mb-0">Tools</p>
                  </div>
                  <div class="col-4">
                    <p class="text-light small mb-0 pl-0">Swag</p>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="fas fa-store store-big-icon white"></i> Shop At
                  Amerus Store
                </p>
              </div> -->
            </div>
          </a>
        </slide>
        <slide key="6">
          <router-link to="/events" class="img-fluid lg-link w-100 ml-2 mr-2">
            <div class="card w-100">
              <img src="../assets/events.png" class="img-fluid" />
              <!-- <div class="card-header pb-0">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow text-left
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  Events
                </h5>
                <p class="white text-shadow text-left">
                  Next Amerus Financial's Event
                </p>
              </div>
              <div class="card-body d-flex p-0">
                <div class="align-self-center">
                  <div class="">
                    <div class="row">
                      <div class="col-4 pr-0">
                        <p class="text-light right-border small mb-0">
                          Company Meeting
                        </p>
                      </div>
                      <div class="col-4">
                        <p class="text-light right-border small mb-0">
                          May 11, 2022
                        </p>
                      </div>
                      <div class="col-4 pl-0">
                        <p class="text-light small mb-0">Lakeland, FL</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="far fa-calendar-alt white"></i> View Up Coming
                  Events
                </p>
              </div> -->
            </div>
          </router-link>
        </slide>
        <slide key="7">
          <a
            href="https://amerusfinancialgroup.com/agent-support/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/team.png" class="img-fluid" />
              <!-- <div class="card-header">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow
                    d-flex
                    align-items-center align-self-center
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  Company Info
                </h5>
                <p class="white text-shadow text-left">
                  About Amerus Financial
                </p>
              </div>
              <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="fas fa-building white"></i> Learn More About Us
                </p>
              </div> -->
            </div>
          </a>
        </slide>

        <template #addons>
          <navigation />
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>

    <div class="row-fluid graphite-gradient p-3 mt-5 mb-5">
      <p class="nav-link link-dark text-left pl-0 mb-0 h2">Compensation</p>
      <carousel :items-to-show="3" :breakpoints="breakpoints">
        <slide key="1">
          <div class="card annual-auction w-100 mr-2">
            <div class="card-header">
              <h5
                class="
                  card-title
                  d-flex
                  align-items-center align-self-center
                  pb-0
                  mb-0
                "
              ></h5>
            </div>
            <div class="card-body d-flex"></div>
            <div class="card-footer align-base mb-3">
              <div class="">
                <div class="row">
                  <div
                    class="
                      col
                      pr-0
                      pl-0
                      mr-3
                      ml-3
                      d-flex
                      justify-content-center
                    "
                  >
                    <p class="d-block float-left mb-0 h2">
                      <em>YTD APV: </em><strong>{{ annualAuctionAPV }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide key="2">
          <div class="card car-bonus w-100 mr-2">
            <div class="card-header">
              <h5
                class="
                  card-title
                  d-flex
                  align-items-center align-self-center
                  pb-0
                  mb-0
                "
              ></h5>
            </div>
            <div class="card-body d-flex"></div>
            <div class="card-footer align-base mb-0">
              <div class="progress">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  :style="{ width: carBonus + '%' }"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <!-- <div class="col pr-0 pl-0 d-flex justify-content-space-between">
                <div class="col-md-4 p-0">
                  <p class="float-left mb-0 h5">
                    <strong>$0</strong>
                  </p>
                </div>
                <div class="col-md-4 p-0">
                  <p class="mb-0 h5">
                    <strong>$75000</strong>
                  </p>
                </div>
                <div class="col-md-4 p-0">
                  <p class="float-right mb-0 h5">
                    <strong>$125,000</strong>
                  </p>
                </div>
              </div> -->
              <div class="col pr-0 pl-0 d-flex justify-content-space-between">
                <div class="col-md-4 p-0">
                  <p class="float-left mb-0">$0</p>
                </div>
                <div class="col-md-4 p-0">
                  <p class="mb-0">$600 / Month</p>
                </div>
                <div class="col-md-4 p-0">
                  <p class="float-right mb-0">$1200 / Month</p>
                </div>
              </div>
            </div>
            <p class="d-block float-left mb-0 h4 mb-3">
              <strong>{{ carBonusAPV }}</strong>
            </p>
          </div>
        </slide>
        <slide key="3">
          <div class="card trip-bonus w-100 mr-2">
            <div class="card-header">
              <h5
                class="
                  card-title
                  d-flex
                  align-items-center align-self-center
                  pb-0
                  mb-0
                "
              ></h5>
            </div>
            <div class="card-body d-flex"></div>
            <div class="card-footer align-base">
              <div class="progress">
                <div
                  class="
                    progress-bar progress-bar-striped progress-bar-animated
                  "
                  role="progressbar"
                  :style="{ width: tripBonus + '%' }"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div class="col pr-0 pl-0 d-flex justify-content-space-between">
                <div class="col-md-6 p-0">
                  <p class="float-left mb-0 h5">
                    <strong>$0</strong>
                  </p>
                </div>

                <div class="col-md-6 p-0">
                  <p class="float-right mb-0 h5">
                    <strong>$100,000</strong>
                  </p>
                </div>
              </div>
            </div>
            <p class="d-block float-left mb-0 h4 mb-3">
              <em>YTD Trip Points: </em><strong>{{ tripBonusAPV }}</strong>
            </p>
          </div>
        </slide>
        <slide key="4">
          <a
            href="https://amerusfinancialgroup.com/bonus/"
            class="w-100 mr-2"
            target="_blank"
          >
            <div class="card bonus">
              <div class="card-header">
                <h5
                  class="
                    card-title
                    d-flex
                    align-items-center align-self-center
                    pb-0
                    mb-0
                  "
                ></h5>
              </div>
              <div class="card-body d-flex"></div>
              <div class="card-footer align-base mb-3">
                <div class="">
                  <div class="row">
                    <div
                      class="
                        col
                        pr-0
                        pl-0
                        mr-3
                        ml-3
                        d-flex
                        justify-content-center
                      "
                    >
                      <p class="d-block float-left mb-0 h2"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </slide>
        <!-- <slide key="4">
          <div class="card bonus-pool w-100 mr-2">
            <div class="card-header">
              <h5
                class="
                  card-title
                  d-flex
                  align-items-center align-self-center
                  pb-0
                  mb-0
                "
              ></h5>
            </div>
            <div class="card-body d-flex"></div>
            <div class="card-footer align-base mb-3">
              <div class="">
                <div class="row">
                  <div
                    class="
                      col
                      pr-0
                      pl-0
                      mr-3
                      ml-3
                      d-flex
                      justify-content-center
                    "
                  >
                    <p class="d-block float-left mb-0 h5">
                      <em>YTD APV: </em><strong>{{ bonusPool }}</strong>
                    </p>
                  </div>
                  <div
                    class="
                      col
                      pr-0
                      pl-0
                      mr-3
                      ml-3
                      d-flex
                      justify-content-center
                    "
                  >
                    <p class="d-block float-left mb-0 h5">
                      <em>Shares: </em><strong>{{ bonusPoolShare }}</strong>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </slide> -->

        <template #addons>
          <navigation @click="showPrev" />
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>

    <div class="row-fluid graphite-gradient p-3 mt-5 mb-5">
      <p class="nav-link link-dark text-left pl-0 mb-0 h2">Technology</p>
      <carousel :items-to-show="3" :breakpoints="breakpoints">
        <slide key="1">
          <a
            href="https://portal.benefitalign.com/brokerengage/amerusfinancial/home/login"
            class="img-fluid lg-link w-100 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/quoting-tools.png" class="img-fluid" />
              <!-- <div class="card-header">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow text-left
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  Quoting Access
                </h5>
                <p class="white text-shadow text-left">Start Your Quote</p>
              </div> -->
              <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="fas fa-cloud white"></i> Quoting Access Log In
                </p>
              </div> -->
            </div>
          </a>
        </slide>
        <!-- <slide key="2">
          <a
            href="https://enrollment123.com/manage/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/enroll.png" class="img-fluid au" />
            </div>
          </a>
        </slide> -->
        <slide key="3">
          <a
            href="https://amerusfinancialgroup.com/products-sold-report-form/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/sales-tile.png" class="img-fluid" />
            </div>
          </a>
        </slide>
        <slide key="4">
          <a
            href="https://app.amerusfinancialgroup.com/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/crm.png" class="img-fluid" />
              <!-- <div class="card-header">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow
                    d-flex
                    align-items-center align-self-center
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  CRM
                </h5>
                <p class="white text-shadow text-left">Manage Your Leads</p>
              </div> -->
              <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="fas fa-cloud white"></i> See Latest Leads
                </p>
              </div> -->
            </div>
          </a>
        </slide>
        <slide key="5">
          <!-- <a
            href="https://app.amerusfinancialgroup.com/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          > -->
          <div class="card w-100">
            <img src="../assets/group.png" class="img-fluid" />
            <!-- <div class="card-header">
                <h5
                  class="
                    card-title
                    heading-large
                    white
                    text-shadow
                    d-flex
                    align-items-center align-self-center
                    pb-0
                    pl-0
                    pr-0
                    mb-0
                  "
                >
                  CRM
                </h5>
                <p class="white text-shadow text-left">Manage Your Leads</p>
              </div> -->
            <!-- <div class="card-footer">
                <p class="nav-link link-light text-left pl-0">
                  <i class="fas fa-cloud white"></i> See Latest Leads
                </p>
              </div> -->
          </div>
          <!-- </a> -->
        </slide>
        <slide key="6">
          <router-link to="/carriers" class="img-fluid lg-link w-100 ml-2 mr-2">
            <div class="card w-100">
              <img src="../assets/carrier-info.png" class="img-fluid" />
            </div>
          </router-link>
        </slide>

        <template #addons>
          <navigation />
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>

    <div class="row-fluid graphite-gradient p-3 mt-5 mb-5">
      <p class="nav-link link-dark text-left pl-0 mb-0 h2">Fast Track</p>
      <carousel :items-to-show="3" :breakpoints="breakpoints">
        <slide key="1">
          <router-link to="/Train" class="mr-2">
            <div class="card w-100 mr-2">
              <img src="../assets/train.png" class="img-fluid" />
            </div>
          </router-link>
        </slide>
        <slide key="2">
          <a
            href="https://amerusfinancialgroup.com/web-print-asset-order-form/"
            class="img-fluid lg-link w-100 ml-2 mr-2"
            target="_blank"
          >
            <div class="card w-100">
              <img src="../assets/pro.png" class="img-fluid" />
            </div>
          </a>
        </slide>
        <slide key="3">
          <router-link
            to="/dashboard"
            class="img-fluid lg-link w-100 ml-2 mr-2"
          >
            <div class="card w-100">
              <img src="../assets/amerus-u.png" class="img-fluid au" />
            </div>
          </router-link>
        </slide>
        <template #addons>
          <navigation />
          <!-- <pagination /> -->
        </template>
      </carousel>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "./Welcome";

import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

// import LineChart from "../components/LineChart";
// import BarChart from "../components/BarChart";
// import MultiSeriesPieChart from "../components/MultiSeriesPieChart.vue";

// import Modal from "./Modal";

export default {
  components: {
    Navbar,
    Welcome,
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      isModalVisible: true,
      breakpoints: {
        // 300px and up
        300: {
          itemsToShow: 1,
        },
        // 700px and up
        700: {
          itemsToShow: 1,
        },
        // 1024 and up
        1000: {
          itemsToShow: 3,
        },
      },
      Carousel: [],
    };
  },

  methods: {
    showPrev() {
      var pos = 0;
      if (pos > 0) {
        //show prev button
      } else {
        //hide prev button
      }
    },
    showNext() {
      //once at the end of the Carousel show hide next button
      var pos = [];
      if (pos.length > 0) {
        //show next button
      } else {
        //hide next button
      }
    },
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
  },
  computed: {
    testName() {
      const user = this.$gapi.getUserData();
      return user;
    },
    annualAuctionAPV() {
      var annualAuction = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return annualAuction.format(
        Number(this.$store.getters.getAnnualAuction) +
          Number(this.$store.getters.getAnnualAuctionCredit)
      );
    },
    annualAuction() {
      var annualAuction = (this.$store.getters.getAnnualAuction / 500000) * 100;
      return annualAuction;
    },
    bonusPool() {
      var bonusPool = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return bonusPool.format(this.$store.getters.getBonusPool);
    },
    bonusPoolShare() {
      var bonusPool = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      });
      var bonusPoolFormat = parseInt(this.$store.getters.getBonusPool);
      var shares = bonusPoolFormat / 75000;
      var sharesFormated = bonusPool.format(Number(shares));

      console.log("Shares: " + typeof sharesFormated);
      return sharesFormated;
    },
    tripBonus() {
      if (this.$store.getters.getTripBonus <= 0) {
        return (this.$store.getters.getUserTripBonusCredit / 100000) * 100;
      } else {
        return (this.$store.getters.getTripBonus / 100000) * 100;
      }
    },
    tripBonusAPV() {
      var tripBonus = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      var total =
        parseInt(this.$store.getters.getTripBonus) +
        parseInt(this.$store.getters.getUserTripBonusCredit);
      return tripBonus.format(total);
    },
    carBonus() {
      if (this.$store.getters.getCarBonus <= 0) {
        return (this.$store.getters.getUserCarBonusCredit / 125000) * 100;
      } else {
        return (this.$store.getters.getCarBonus / 125000) * 100;
      }
    },
    carBonusAPV() {
      var total =
        parseInt(this.$store.getters.getCarBonus) +
        this.$store.getters.getUserCarBonusCredit;
      if (total >= 75000) {
        return "Qualified";
      } else {
        return "Not Qualified";
      }
    },
    amerusBucks() {
      return this.$store.getters.getAmerusBucks;
    },
    recog() {
      const fraction = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      var premium = this.$store.getters.getPremium;
      var premiumCredit = this.$store.getters.getPremiumCredit;
      var recog = 250000 - (premium + premiumCredit);
      return fraction.format(recog);
    },

    totalOfEachSale() {
      return this.$store.getters.getTotalOfEachSale;
    },
    labelOfEachSale() {
      return this.$store.getters.getLabelOfEachSale;
    },
  },
  mounted() {
    document.body.classList.add("hero-bg");
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
}
.lg-link {
  font-size: 1.3em !important;
}
p {
  color: #ffe29b;
}
.white {
  color: #fff !important;
}
h5 {
  justify-content: space-between;
}
.heading-large {
  font-size: 2em;
}
@media only screen and (max-width: 1300px) {
  .heading-large {
    font-size: 1em;
  }
}
.link-light {
  color: #fff !important;
}
/* .card {
  background-color: #2a2a2a;
} */
.text-shadow {
  text-shadow: 1px 1px 4px rgb(0 0 0 / 10%);
}
.card-360 {
  min-height: 360px;
}
.card-title {
  /* color: #afafb4; */
  /* color: #fff; */
  /* color: #ffe29b; */
  color: #ffffff;
  padding-bottom: 10%;
}
.card-footer {
  border-top: none;
  background-color: rgba(0, 0, 0, 0.09);
}

.card-header {
  background-color: transparent;
  border-bottom: none;
  color: #fff;
}

.fa-calendar-alt,
.fa-graduation-cap,
.fa-building,
.fa-money-bill-alt,
.fa-medal,
.fa-file-invoice,
.fa-funnel-dollar,
.fa-cloud,
.store-big-icon,
.icon-Amerus {
  /* color: #ffe29b; */
  /* text-shadow: 2px 2px 2px #000000; */
  font-size: 2em;
  color: #ffffff;
  padding: 10px;
}
.icon-Amerus {
  font-size: 3em;
}
.icon-Amerus:before {
  content: "\e900";
}
/* .big-icon {
  font-size: 25em;
} */
.nav-link {
  color: #fff;
}

.single-event {
  /* background: rgb(31, 28, 48, 1); */
  background: rgb(31, 28, 48, 0.6);
}
.single-event:hover {
  background: rgb(31, 28, 48, 0.4);
}

.align-base {
  align-items: baseline;
}

.yellow {
  color: yellow;
}
.crop-wrapper {
  overflow: hidden;
}
.img-crop-bottom-235 {
  margin-bottom: -235px;
}
.bg-success {
  background: #d5f7d5 !important;
}
.bg-info {
  background: #ecc884 !important;
}
.bg-warning {
  background: #fff !important;
}
/* .comp-background {
  background-image: url("../assets/CompCard-01.jpg");
} */
.amerus-store-bg {
  background-image: url("../assets/TheAmerusStore-01.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}

.amerus-ua-bg {
  background-image: url("../assets/amerus-u.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}
.amerus-events-bg {
  background-image: url("../assets/events.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}
.amerus-bonus-bg {
  background-image: url("../assets/bonus.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}
.amerus-qa-bg {
  background-image: url("../assets/quoting-tools.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}
.crm-bg {
  background-image: url("../assets/crm.png");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}
.carrier-access-bg {
  background-image: url("../assets/carrier-info.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}

.company-info-bg {
  background-image: url("../assets/CompanyInfo.jpg");
  min-height: 565px;
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
}

.annual-auction {
  background-image: url("../assets/annual-auction.png");
  height: 565px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bonus {
  background-image: url("../assets/bonus.png");
  height: 565px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.car-bonus {
  background-image: url("../assets/car-bonus.png");
  height: 565px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.trip-bonus {
  background-image: url("../assets/trip.png");
  height: 565px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bonus-pool {
  background-image: url("../assets/bonus-pool.png");
  height: 565px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.nav-link {
  color: #ffe29b;
}
.d-flex > .align-self-center {
  flex-grow: 1;
}
.leader-board-BG {
  background-image: url("../assets/leaderboard.jpg");
  /* background: #000000; */
  height: 360px;
  background-repeat: no-repeat;
  background-size: contain;
  /* background-position: center; */
}

.round {
  border-radius: 50%;
}
.leader-board {
  background: rgb(0, 0, 0, 0.5);
  /* background: #1c1c1c; */
  border-radius: 42px;
}
.leader-board:hover {
  background: rgb(31, 28, 48, 0.3);
}
.right-border {
  border-right: thin solid #fff;
}
</style>

<style>
/* .carousel__prev {
  display: none !important;
} */
</style>