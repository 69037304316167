<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <CompensationBonusesContent />
    </div>
    <a @click="$router.go(-1)" class="btn btn-primary mt-4"
      ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
    >
  </div>
</template>

<script>
import CompensationBonusesContent from "../components/CompensationBonusesContent";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "CompensationBonuses",
  components: {
    CompensationBonusesContent,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>
<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
</style>