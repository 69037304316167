<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0 px-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="black p-3 pt-5">
            <!-- <h1 class="text-light mb-5">Carriers</h1> -->
            <div class="row mb-3">
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/American-Amicable.png"
                  alt="American-Amicable"
                  class="img-fluid"
                />
              </div>

              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Americo.png"
                  alt="Americo"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Liberty-Bankers.png"
                  alt="Liberty-Bankers"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Manhattan-Life.png"
                  alt="Manhattan-Life"
                  class="img-fluid"
                />
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/National-General.png"
                  alt="National-General"
                  class="img-fluid"
                />
              </div>

              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Prosperity.png"
                  alt="Prosperity"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Royal-Neighbors.png"
                  alt="Royal-Neighbors"
                  class="img-fluid"
                />
              </div>
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <img
                  src="../assets/Washington-National.png"
                  alt="Washington-National"
                  class="img-fluid"
                />
              </div>
            </div>
            <a @click="$router.go(-1)" class="btn btn-primary mt-4"
              ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "CompanyInfo",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
}
p,
h5 {
  color: #fff;
}
</style>
