<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <!-- <GlanceToolBar /> -->
          <div class="row-fluid">
            <h2 class="text-light text-left">Top Performers | Monthly</h2>
            <div class="card-deck">
              <div class="card">
                <img
                  src="../assets/RobArchiveHover.jpg"
                  alt="Rob Skinner"
                  class="img-fluid round mx-auto mt-3"
                  width="150"
                />
                <h3 class="card-title text-light mt-3 mb-0">Rob Skinner</h3>
                <p class="lead mb-0">Vice President</p>
                <hr />
                <div class="card-body">
                  <div class="d-flex align-items-center align-self-center">
                    <h5
                      class="
                        card-title
                        d-flex
                        align-items-center align-self-center
                        pb-0
                        w-100
                      "
                    >
                      100 / Closed Deals<span class="float-right"
                        >1000 Calls</span
                      >
                    </h5>
                  </div>

                  <p class="card-text">
                    <Button
                      location="/profile/"
                      name="View Profile"
                      icon="fas fa-user-tie"
                    />
                  </p>
                </div>
              </div>
              <div class="card">
                <img
                  src="../assets/RickyArchiveHovers.jpg"
                  alt="Ricky Verduzc"
                  class="img-fluid round mx-auto mt-3"
                  width="150"
                />
                <h3 class="card-title text-light mt-3 mb-0">Ricky Verduzc</h3>
                <p class="lead mb-0">Senior Feild Underwriter</p>
                <hr />
                <div class="card-body">
                  <div class="d-flex align-items-center align-self-center">
                    <h5
                      class="
                        card-title
                        d-flex
                        align-items-center align-self-center
                        pb-0
                        w-100
                      "
                    >
                      80 / Closed Deals<span class="float-right"
                        >800 Calls</span
                      >
                    </h5>
                  </div>
                  <p class="card-text">
                    <Button
                      location="/profile/"
                      name="View Profile"
                      icon="fas fa-user-tie"
                    />
                  </p>
                </div>
              </div>
              <div class="card">
                <img
                  src="../assets/NickArchiveHovers.jpg"
                  alt="Curtis Dugger"
                  class="img-fluid round mx-auto mt-3"
                  width="150"
                />
                <h3 class="card-title text-light mt-3 mb-0">Nick Potter</h3>
                <p class="lead mb-0">Senior Feild Underwriter</p>
                <hr />
                <div class="card-body">
                  <div class="d-flex align-items-center align-self-center">
                    <h5
                      class="
                        card-title
                        d-flex
                        align-items-center align-self-center
                        pb-0
                        w-100
                      "
                    >
                      40 / Closed Deals<span class="float-right"
                        >400 Calls</span
                      >
                    </h5>
                  </div>
                  <p class="card-text">
                    <Button
                      location="/profile/"
                      name="View Profile"
                      icon="fas fa-user-tie"
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="row-fluid mt-4">
            <h2 class="text-light text-left">All Users</h2>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Rank</th>
                    <th scope="col">Name</th>
                    <th scope="col">Calls</th>
                    <th scope="col">Sales</th>
                    <th scope="col">Profile</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">4</th>
                    <td>
                      <img
                        src="../assets/Kim.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Kimberly Skinner
                    </td>
                    <td>300</td>
                    <td>30</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">5</th>
                    <td>
                      <img
                        src="../assets/JacobThornton.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Jacob Thornton
                    </td>
                    <td>200</td>
                    <td>20</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">6</th>
                    <td>
                      <img
                        src="../assets/LarryJones.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Larry Jones
                    </td>
                    <td>100</td>
                    <td>10</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">7</th>
                    <td>
                      <img
                        src="../assets/JoeSmith.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Joe Smith
                    </td>
                    <td>900</td>
                    <td>90</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">8</th>
                    <td>
                      <img
                        src="../assets/MichaleCampbell.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Michale Campbell
                    </td>
                    <td>800</td>
                    <td>80</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">9</th>
                    <td>
                      <img
                        src="../assets/CheyenneCampbell.jpg"
                        class="round mr-2 img-fluid"
                        width="50"
                      />Cheyenne Campbell
                    </td>
                    <td>10</td>
                    <td>2</td>
                    <td>
                      <Button
                        location="/profile/"
                        name="View Profile"
                        icon="fas fa-user-tie"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import Button from "../components/Button";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "Leaderboard",
  components: {
    Navbar,
    Welcome,
    Button,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
th,
td {
  text-align: inherit;
  color: #fff;
}
.card {
  background: #2a2a2a;
}
hr {
  border-top: thin solid grey;
}
.card-title {
  border: none;
}
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #2a2a2a;
}

th,
td {
  text-align: initial;
}
</style>