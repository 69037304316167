<template>
  <div class="container-fluid">
    <div class="row">
      <Sidebar />

      <div id="content" class="col-xl-10 col-lg-10 col-md-10 col-sm-12">
        <div class="row-fluid">
          <Welcome />
          <HorizontalToolBar />
          <div class="row-fluid">
            <!-- 16:9 aspect ratio -->
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../components/Sidebar";
import Welcome from "../components/Welcome";
import HorizontalToolBar from "../components/HorizontalToolBar";
export default {
  name: "QuotingAccess",
  components: {
    Sidebar,
    HorizontalToolBar,
    Welcome,
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
</style>