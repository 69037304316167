<template>
  <div id="sidebar" class="col-xs-12 col-md-5 col-xl-3">
    <ButtonGroup>
      <Button location="/dashboard" name="Dashboard" icon="fas fa-home" />
      <Button
        location="/analytics"
        name="Annual Production (Analytics)"
        icon="fas fa-money-bill"
      />
      <Button
        location="/district-manager"
        name="District Manager"
        icon="fas fa-user-tie"
      />
      <Button
        location="/regional-manager"
        name="Regional Manager"
        icon="fas fa-user"
      />
      <Button
        location="https://backoffice.amerusfinancialgroup.com/"
        name="Log Out"
        icon="fas fa-sign-out-alt"
        @click="logOut"
      />
    </ButtonGroup>
  </div>
</template>

<script>
import ButtonGroup from "./ButtonGroup";
import Button from "./Button";
export default {
  name: "Sidebar",
  components: {
    ButtonGroup,
    Button,
  },
  methods: {
    logOut() {
      this.$store.dispatch("logOut");
    },
  },
};
</script>

<style scoped>
#sidebar {
  background-color: #1f1c30 !important;
}
</style>

