<template>
  <div class="col-sm-12 col-md-12 col-lg-12 mt-md-big">
    <div class="row-fluid">
      <!-- <p class="lead text-left">Your Amerus Back Office</p> -->
      <!-- <h5 class="text-left text-shadow">
        Welcome, <span class="name">{{ name }}</span>
      </h5> -->
      <!-- <h5 class="text-left text-shadow">
        <span class="name">{{ name }}</span>
      </h5> -->
    </div>
    <div class="row hero-bg align-items-center">
      <!-- <div class="col-sm-12 col-lg-6 pl-0">
        <p
          class="
            money-green
            mb-0
            d-flex
            align-items-center align-self-center
            text-left
          "
        >
          YOUR BACK OFFICE
        </p>
        <h5 class="text-left mb-0">
          <span class="name">{{ name }}</span>
        </h5>
        <p class="text-light text-left">Senior Feild Underwriter</p>
        <p class="lead text-left border-bottom pb-2">
          <span class="money-green"
            >Revenue:
            <span class="light-grey"
              >${{ new Intl.NumberFormat().format(revenue) }}</span
            >
          </span>
          <span class="sale-call-out"> Sales: {{ sales }} </span>
          <span class="light-grey"> Amerus Bucks: $2,000 | </span>
          <span class="money-green"> Leaderboard Rank: 26 </span>
        </p>
        <div class="journal">
          <h2 class="text-light text-left">Journal Entries</h2>
          <div class="" v-for="post in posts" :key="post.id">
            <p class="text-left">
              <strong class="lead">{{ post.title.rendered }}</strong
              ><span
                class="text-light text-left"
                v-html="post.excerpt.rendered"
              ></span>
            </p>
          </div>
          <router-link
            class="btn btn-primary float-left mb-3 text-light"
            to="/AmerusJournal"
            >View Journal</router-link
          >
        </div>
      </div> -->
      <!-- <div class="col-6 d-none d-lg-block">
        <div class="row">
          <div class="col-12">
            <h2 class="text-light text-shadow text-left display-4 pl-4">
              Want To Make More Money? Buy Some Leads Today
            </h2>
            <p class="text-light text-shadow text-left pl-4">
              By purchasing leads, insurance agents can access key contact
              information. This almost instantly increases their chances of
              turning prospects into customers. There are many benefits to
              buying leads including cost and time savings, more conversion
              rates, easy intergration, and receiving high-quality leads.
            </p>
            <router-link class="btn btn-primary ml-4 float-left" to="/store"
              >Buy Leads</router-link
            >
          </div>
        </div>
      </div> -->
      <div class="col-12">
        <div class="row">
          <div class="col-lg-12 offset-xl-2 col-xl-3 p-0 featured-boarder">
            <a
              href="https://amerusfinancialgroup.com/agent-login/"
              target="_blank"
              ><img src="../assets/FeatureItem-01.png" class="img-fluid"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    name() {
      return this.$store.getters.getName;
    },
    revenue() {
      return this.$store.getters.getRevenueSinceHire;
    },
    sales() {
      return this.$store.getters.getSalesSinceHire;
    },
    job() {
      return this.$store.getters.getSalesSinceHire;
    },
    posts() {
      return this.$store.getters.getAmerusJounral;
    },
  },
  methods: {
    // fetchData() {
    //   this.$store.dispatch("loadAgentData");
    //   this.$store.dispatch("loadAgentSalesData");
    //   this.$store.dispatch("amerusProducts");
    //   this.$store.dispatch("loadAmerusUniversity");
    // },
  },
  mounted() {
    // this.fetchData();
  },
};
</script>

<style scoped>
.featured-boarder {
  border: thin solid #ffe29b;
}
.card {
  background-color: #2a2a2a;
}
.lead {
  /* color: #787878; */
  color: #ffe29b;
}
h5 {
  color: #ffe29b;
}
.name {
  font-size: 3rem;
  /* color: #afafb4; */
  color: #ffe29b;
}

.round {
  border-radius: 50%;
}
.d-flex > .align-self-center {
  flex-grow: 1;
}

h5 {
  justify-content: space-between;
}

.fa-medal {
  font-size: 2em;
  color: #ffe29b;
  text-shadow: 2px 2px 2px #000000;
  padding: 10px;
}

.circular {
  height: 100px;
  width: 100px;
  position: relative;
  transform: scale(2);
}
.circular .inner {
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  background: #dde6f0;
  border-radius: 100%;
}
.circular .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 18px;
  font-weight: 500;
  color: #4158d0;
}
.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-border-radius: 100%;
  clip: rect(0px, 100px, 100px, 50px);
}
.circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
  background: #4158d0;
}
.circle .left .progress {
  z-index: 1;
  animation: left 4s linear both;
}
@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index: 3;
}
.circle .right .progress {
  animation: right 4s linear both;
  animation-delay: 4s;
}
@keyframes right {
  100% {
    transform: rotate(180deg);
  }
}
.border-bottom {
  /* border-bottom: #fff thin solid !important; */
  border-bottom: #ffe29b thin solid !important;
}
</style>