<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <HorizontalToolBar />
          <div class="container-fluid pl-0">
            <div class="row">
              <!---CONTENT HERE---->
              <div class="col-md-2 col-sm-12 float-left pr-0">
                <img
                  src="../assets/Timothy-Baggett.png"
                  alt="Tim Baggett"
                  class="img-fluid round float-left"
                />
              </div>
              <div class="col-md-9 col-sm-12 float-right">
                <h2 class="text-light text-left">Tim Baggett</h2>
                <p class="text-light text-left">
                  Tim Baggett is passionate about training financial services
                  professionals to protect families and create generational
                  wealth through marketing a proprietary suite of financial
                  vehicles including benefits available to small business owners
                  exclusively through the National Business Benefit Alliance and
                  Amerus Financial Group.
                </p>
                <p class="text-light text-left">
                  His intensity and drive toward this mission stems from the
                  many financial summits and even the reversals he and his loved
                  ones have experienced, uniquely equipping him to impart his
                  experience, skills and talents to training and supporting
                  licensed professionals who are excellently equipped to growing
                  and protecting families' legacies.
                </p>

                <p class="text-light text-left">
                  As Vice President at Amerus Financial Group, Mr. Skinner is
                  responsible for a veritable army of similarly oriented servant
                  leaders who are focused on connecting clients with custom
                  tailored wealth generation vehicles with safety and tax
                  favored considerations at the forefront.
                </p>
              </div>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "District Manager",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
</style>
