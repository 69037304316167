<template>
  <div class="container-fluid p-3 black">
    <footer>
      <p class="text-light">
        Copyright &copy; {{ getCurrentYear() }} {{ setName() }}, All Rights
        Reserved
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    setName() {
      return "Amerus Finanical Group";
    },
  },
};
</script>

<style scoped>
</style>