<template>
  <div>
    <canvas :id="elementID"></canvas>
    <!-- <input type="checkbox" checked data-toggle="toggle" data-size="lg" /> -->
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "LineChart",
  props: {
    element: String,
    ChartBackgroundColor: String,
    ChartBorderColor: String,
    ChartLabels: [Array, Object],
    ChartData: [Array, Object],
    DatasetLabel: String,
  },
  data() {
    return {
      elementID: this.element,
      lineChartData: {
        type: "line",
        data: {
          labels: this.ChartLabels,
          datasets: [
            {
              label: this.DatasetLabel,
              data: this.ChartData,
              backgroundColor: this.ChartBackgroundColor,
              borderColor: this.ChartBorderColor,
              borderWidth: 2,
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            y: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    };
  },
  mounted() {
    const ctx = document.getElementById(this.element);
    const lineChart = new Chart(ctx, this.lineChartData);
    lineChart.update();
    //console.log(this.chartData);
  },
};
</script>