<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="row mt-4">
            <div class="col-md-12 col-lg-6">
              <img
                :src="productImage"
                :alt="productName"
                width="1000"
                class="img-fluid float-left"
              />
            </div>
            <div class="col-md-12 col-lg-6">
              <h1 class="text-light text-left">{{ productName }}</h1>
              <p class="text-left" v-html="productDescription"></p>
              <!-- <p class="card-text" v-html="product.description"></p> -->
              <div class="row">
                <!-- <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Quantity
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <button
                      class="dropdown-item"
                      href="#"
                      v-for="options in product.attributes[0].options"
                      :key="options"
                    >
                      {{ options }}
                    </button>
                  </div>
                </div> -->
                <!-- <label for="Quanity" class="text-light">Quantity</label>

                <select id="dropdown" class="form-control" name="Quanity">
                  <option
                    v-for="options in product.attributes[0].options"
                    :key="options"
                    :value="options"
                  >
                    {{ options }}
                  </option>
                </select> -->
              </div>
              <br />
              <div class="row">
                <button
                  class="btn btn-outline-primary float-left"
                  @click="addToCart(product)"
                >
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
        </div>
        <a @click="$router.go(-1)" class="btn btn-primary mt-4"
          ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
        >
        <router-link class="nav-link link-dark p-0" to="/checkout"
          >Check Out</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "Store",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
    addToCart(theProduct) {
      console.log(theProduct);
      this.$store.dispatch("addToCart", {
        theProduct,
      });
    },
  },
  computed: {
    productId() {
      const page = this.Products.find(
        (x) => x.id === parseInt(this.$route.params.id)
      );
      return page.id;
    },
    product() {
      const page = this.Products.find(
        (x) => x.id === parseInt(this.$route.params.id)
      );
      console.log("page: " + page);
      return page;
    },
    productDescription() {
      const page = this.Products.find(
        (x) => x.id === parseInt(this.$route.params.id)
      );
      return page.description;
    },
    productImage() {
      const page = this.Products.find(
        (x) => x.id === parseInt(this.$route.params.id)
      );
      return page.images[0].src;
    },
    productName() {
      const page = this.Products.find(
        (x) => x.id === parseInt(this.$route.params.id)
      );
      return page.name;
    },
    Menu() {
      return this.$store.getters.getMenu;
    },
    Products() {
      return this.$store.getters.getAmerusProducts;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
  box-shadow: rgb(0 0 0) 0px 5px 20px 5px !important;
  background: padding-box;
}
p,
h5 {
  color: #fff;
}
</style>
