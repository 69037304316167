<template>
  <div class="container">
    <div class="row">
      <div class="mr-auto ml-auto p-3 mt-3 mb-3">
        <div class="card login-form">
          <div class="card-body">
            <h3 class="card-title text-center">Log in to your account</h3>
            <div class="card-text">
              <p class="lead">Hello, there!</p>
              <!--
			<div class="alert alert-danger alert-dismissible fade show" role="alert">Incorrect username or password.</div> -->

              <!---better login please---->
              <!----https://github.com/cornflourblue/vue-vuex-registration-login-example/blob/master/src/login/LoginPage.vue--->
              <!-- @submit.prevent="handleSubmit" add to form -->
              <form @submit.prevent="handleSubmit">
                <!-- to error: add class "has-danger" -->
                <div class="form-group">
                  <div v-show="error != ''" class="loginError">
                    <strong>ERROR:</strong> Incorrect username or password.
                  </div>
                  <label for="exampleInputEmail1">Username</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    @input="username"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputPassword1">Password</label>
                  <!-- <a href="#" style="float: right; font-size: 12px"
                    >Forgot password?</a
                  > -->
                  <input
                    type="password"
                    class="form-control form-control-sm"
                    id="exampleInputPassword1"
                    @input="password"
                  />
                </div>
                <!-- <button
                  type="submit"
                  class="btn btn-primary btn-block"
                  @click="login"
                >
                  Login
                </button> -->
                <!-- <Button
                  type="button"
                  location="/dashboard"
                  name="Login"
                  :icon="
                    getHasPermission
                      ? 'fas fa-spinner fa-spin'
                      : 'fas fa-level-up-alt'
                  "
                /> -->

                <span>
                  <i
                    :class="
                      getHasPermission
                        ? 'fas fa-spinner fa-spin text-light'
                        : ''
                    "
                  ></i>
                  <input type="submit" class="btn btn-primary btn-block" />
                </span>
                <!-- <div class="sign-up">
                  Don't have an account? <a href="#">Create One</a>
                </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      isLoggingIn: false,
    };
  },
  components: {},
  computed: {
    getHasPermission() {
      return this.$store.getters.getHasPermission;
    },
    error() {
      return this.$store.getters.getError;
    },
    getUserName() {
      return this.$store.getters.getUserName;
    },
    getPassword() {
      return this.$store.getters.getPassword;
    },
    accessToken() {
      return this.$gapi.getUserData().accessToken;
    },
  },
  methods: {
    handleSubmit(e) {
      this.submitted = true;
      console.log("submitted: " + this.submitted);

      if (this.getUserName != "") {
        if (this.getPassword != "") {
          this.login();
        }
      }
      console.log(e);
    },
    async login() {
      window.sessionStorage.removeItem("JWT");
      window.sessionStorage.removeItem("vuex");
      if (this.error) {
        this.$store.dispatch("resetLogError");
      }
      return await new Promise((resolve, reject) => {
        this.$store
          .dispatch("userLogin")
          .then((responseLogin) => {
            // request succeeded
            try {
              if (this.$gapi.isAuthenticated() && this.$gapi.isSignedIn()) {
                return;
              } else {
                this.$gapi.login();
              }
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(responseLogin);
          })
          .then((responseAccessToken) => {
            // request succeeded
            try {
              this.$store.dispatch("userAccessToken", this.accessToken);
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(responseAccessToken);
          })

          .then((responseCurrent) => {
            // request succeeded
            try {
              return new Promise((resolve, reject) => {
                this.$store
                  .dispatch("setCurrentYear")
                  .then((responseReset) => {
                    // request succeeded
                    try {
                      //this.$store.dispatch("setCurrentYear");
                      var today = new Date();
                      var year = today.getFullYear().toString();
                      // var year = "2024";
                      var zero = "0000";
                      console.log(
                        "TESTINGGG CURRENT YEAR" +
                          this.$store.getters.getProfileYear
                      );

                      if (this.$store.getters.getProfileYear == "") {
                        this.$store.dispatch("updateCurrentYear", year);
                      }

                      if (this.$store.getters.getProfileYear < year) {
                        this.$store.dispatch("resetUserPremiums", zero);
                        this.$store.dispatch("updateCurrentYear", year);
                      }
                    } catch (error) {
                      console.error("ERROR: " + error);
                    }

                    resolve(responseReset);
                  })
                  .catch((error) => {
                    // request failed
                    console.log("Please Try Again");

                    this.$store.dispatch("logError");
                    reject(error); // return error to calling function
                  });
              });
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(responseCurrent);
          })

          .then((responseCurrentQuarter) => {
            // request succeeded
            try {
              return new Promise((resolve, reject) => {
                this.$store
                  .dispatch("setCurrentQuarter")
                  .then((responseReset) => {
                    // request succeeded
                    try {
                      //this.$store.dispatch("setCurrentQuarter");
                      var today = new Date();
                      var year = today.getFullYear().toString();
                      var quarter = Math.floor((today.getMonth() + 3) / 3);
                      // var year = "2024";
                      var zero = "0000";
                      console.log(
                        "TESTINGGG CURRENT Quarter" +
                          this.$store.getters.getProfileQuarter
                      );

                      if (this.$store.getters.getProfileQuarter == "") {
                        this.$store.dispatch("updateCurrentQuarter", quarter);
                      }

                      if (
                        this.$store.getters.getProfileQuarter != quarter ||
                        this.$store.getters.getProfileYear != year
                      ) {
                        this.$store.dispatch("resetUserCarBonus", zero);
                        this.$store.dispatch("updateCurrentQuarter", quarter);
                      }
                    } catch (error) {
                      console.error("ERROR: " + error);
                    }

                    resolve(responseReset);
                  })
                  .catch((error) => {
                    // request failed
                    console.log("Please Try Again");

                    this.$store.dispatch("logError");
                    reject(error); // return error to calling function
                  });
              });
            } catch (error) {
              console.error("ERROR: " + error);
            }

            resolve(responseCurrentQuarter);
          })

          // Loading Screen
          .then((responseLoading) => {
            // request succeeded
            try {
              this.$router.push(
                "https://backoffice.amerusfinancialgroup.com/loading"
              );
            } catch (error) {
              // this.isLoggingIn = false;
              console.error("ERROR: " + error);
              // expected output: ReferenceError: nonExistentFunction is not defined
              // Note - error messages will vary depending on browser
            }

            resolve(responseLoading); // return response data to calling function
          })
          .catch((error) => {
            // request failed
            console.log("Please Try Again");

            this.$store.dispatch("logError");
            reject(error); // return error to calling function
          });
      });
    },
    username(e) {
      this.$store.commit("setUserName", e.target.value);

      //console.log("User: " + this.$store.getters.getUserName);
      //return this.$store.getters.getUserName;
    },
    password(e) {
      this.$store.commit("setPassword", e.target.value);
      //console.log(this.$store.getters.getPassword);
      //return this.$store.getters.getUserName;
    },
  },
};
</script>

<style scoped>
.card-title,
.form-group,
.sign-up {
  color: #fff;
}
</style>




