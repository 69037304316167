<template>
  <div class="w-100 mr-4 ml-4">
    <div class="progress">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        :style="{ width: progress + '%' }"
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="">
      <div class="row"></div>
    </div>
    <div class="">
      <div class="row-fluid">
        <!-- <div class="col pr-0 pl-0 mr-3 ml-3">
          <p class="d-block float-left mb-0 h2">
            <em>YTD APV: </em>
            <strong>{{ premium }}</strong>
          </p>
          <p class="d-block float-right mb-0 h2">
            <em>All Star: </em>
            <strong>${{ new Intl.NumberFormat().format(nextRank) }}</strong>
          </p>
        </div> -->
        <div class="col pr-0 pl-0 d-flex justify-content-space-between">
          <div class="col-md-4 p-0">
            <p class="float-left mb-0 h5">
              <strong>{{ premium }}</strong>
            </p>
          </div>
          <div class="col-md-4 p-0">
            <p class="mb-0 h5">
              <strong>$250,000</strong>
            </p>
          </div>
          <div class="col-md-4 p-0">
            <p class="float-right mb-0 h5">
              <strong>$500,000</strong>
            </p>
          </div>
        </div>
        <div class="col pr-0 pl-0 d-flex justify-content-space-between">
          <div class="col-md-4 p-0">
            <p class="float-left mb-0">YTD APV</p>
          </div>
          <div class="col-md-4 p-0">
            <p class="mb-0">All Star</p>
          </div>
          <div class="col-md-4 p-0">
            <p class="float-right mb-0">Diamond</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {
    premium() {
      const fraction = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return fraction.format(
        this.$store.getters.getPremium + this.$store.getters.getPremiumCredit
      );
    },
    currentRank() {
      return this.$store.getters.getCurrentRank;
    },
    progress() {
      return (
        ((this.$store.getters.getPremium +
          this.$store.getters.getPremiumCredit) /
          this.$store.getters.getNextRank) *
        100
      );
    },
    nextRank() {
      return this.$store.getters.getNextRank;
    },
    revenue() {
      return this.$store.getters.getRevenueSinceHire;
    },
    sales() {
      return this.$store.getters.getSalesSinceHire;
    },
  },
};
</script>


<style scoped>
.container-fluid {
  /* background-color: #2a2a2a; */
  background-color: #000;
}
.fa-calendar-alt,
.fa-dollar-sign,
.fa-money-check-alt {
  color: #ffffff;
  font-size: 2em;
  margin-top: 10px;
  background: #1f1c30;
  padding: 10px;
}
p {
  /* color: #afafb4; */
  color: #ffe29b;
}
.center-content {
  justify-content: center;
  display: flex;
}
.f-1-5rem {
  font-size: 1.5rem;
}
.progress-bar {
  background: #d5f7d5;
}
</style>