<template>
  <div class="container-fluid">
    <div class="row">
      <sidebar-menu
        :collapsed="true"
        :menu="Menu"
        :show-one-child="true"
        widthCollapsed="0px"
        :disableHover="true"
        width="320px"
      />
      <div id="content" class="demo col pt-0 px-0">
        <div class="row-fluid">
          <navbar />
          <Welcome />
          <div class="black p-3 pt-5">
            <!-- <h1 class="text-light mb-5">Carriers</h1> -->
            <div class="row d-flex justify-content-center mb-3">
              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <a
                  href="https://www.facebook.com/groups/theamerusway/posts/685292026009461/"
                  target="_blank"
                >
                  <img
                    src="../assets/health.png"
                    alt="health"
                    class="img-fluid"
                  />
                </a>
              </div>

              <div class="col-md-12 col-lg-3 mb-3 pb-3">
                <a href="https://ffltridentlife.com/" target="_blank">
                  <img src="../assets/life.png" alt="life" class="img-fluid" />
                </a>
              </div>
            </div>
            <a @click="$router.go(-1)" class="btn btn-primary mt-4"
              ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";
import { SidebarMenu } from "vue-sidebar-menu";

export default {
  name: "CompanyInfo",
  components: {
    Navbar,
    Welcome,
    SidebarMenu,
  },
  methods: {
    onResize() {
      if (window.innerWidth <= 767) {
        this.isOnMobile = true;
        this.collapsed = true;
      } else {
        this.isOnMobile = false;
        this.collapsed = false;
      }
    },
  },
  computed: {
    Menu() {
      return this.$store.getters.getMenu;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.round {
  border-radius: 100% 100% 100% 100%;
}
.card {
  border: none;
}
p,
h5 {
  color: #fff;
}
</style>
