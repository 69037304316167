<template>
  <div>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12 p-0 m-0">
          <NBBAFooter />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NBBAFooter from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NBBAFooter,
  },
  mounted() {
    window.setInterval(this.$gapi.refreshToken(), 2.7e6);
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

body {
  /* background-color: #1f1c30; */
  /* background: linear-gradient(
    270deg,
    rgba(83, 83, 83, 1) 0%,
    rgba(44, 44, 44, 1) 52%,
    rgba(55, 55, 55, 1) 100%,
    rgba(134, 134, 134, 1) 100%
  ); */
  background-color: #000000;
}
.hero-bg {
  /* background-color: #1f1c30; */
  /* background: linear-gradient(
    270deg,
    rgba(83, 83, 83, 1) 0%,
    rgba(44, 44, 44, 1) 52%,
    rgba(55, 55, 55, 1) 100%,
    rgba(134, 134, 134, 1) 100%
  ); */
  background-image: url("./assets/screens.png");
  background-repeat: no-repeat;
  /* background-size: contain; */
  background-position: center;
  height: 708px;
  background-color: #000000;
  background-attachment: fixed;
}
@media only screen and (max-width: 600px) {
  .hero-bg {
    height: 300px;
  }
}
@media only screen and (max-width: 300px) {
  .hero-bg {
    height: 200px;
  }
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.background {
  background-color: #1f1c30;
}
.text-shadow {
  text-shadow: 1px 1px 4px #000000;
}
.progress {
  background-color: #828282;
  height: 20px;
}
.card {
  border: none;
}
.card,
.h-tools {
  /* box-shadow: rgb(0 0 0) 0px 5px 20px 5px; */
  background: padding-box;
}
.shadow {
  box-shadow: rgb(0 0 0) 0px 5px 20px 5px;
}
.lead {
  color: #d3d448;
}
.carousel__next {
  transform: translate(0, -50%) !important;
  height: 100% !important;
  background: rgb(0, 0, 0, 0.4) !important;
  width: 5% !important;
  border-radius: 0 !important;
}
.carousel__prev {
  transform: translate(0, -50%) !important;
  height: 100% !important;
  background: rgb(0, 0, 0, 0.4) !important;
  width: 5% !important;
  border-radius: 0 !important;
}
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none !important;
}
/* .carousel__next > .carousel__icon,
.carousel__prev > .carousel__icon {
  display: none;
} */

button:focus {
  outline: none;
}

/* .carousel__next:hover > .carousel__icon,
.carousel__prev:hover > .carousel__icon {
  display: flex;
} */
.carousel__icon {
  font-size: 4em;
}
.money-green {
  color: #d5f7d5 !important;
}
.light-grey {
  color: #e5e5e5;
}
.medium-grey {
  color: #515151;
}
.sale-call-out {
  background: #515151;
  color: #ffffff;
  padding: 5px;
  margin: 10px;
}
.border-right {
  border-right: thick solid #d5f7d5 !important;
}
.border-top {
  border-top: thick solid #d5f7d5 !important;
}
.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(0, 0, 0, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.15) 50%,
    rgba(0, 0, 0, 0.15) 75%,
    transparent 75%,
    transparent
  ) !important;
  background-size: 1rem 1rem !important;
}

.v-sidebar-menu {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 0;
  position: fixed;
  text-align: left;
  top: 0;
  -webkit-transition: max-width 0.3s ease;
  transition: max-width 0.3s ease;
  width: 100%;
  z-index: 999;
  box-shadow: rgb(0 0 0) 2px 2px 10px -5px;
}
.v-sidebar-menu,
.v-sidebar-menu * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.v-sidebar-menu .vsm--scroll-wrapper {
  -webkit-box-flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  min-height: 0;
  position: relative;
}
.v-sidebar-menu .vsm--scroll-wrapper:active .vsm--scroll-bar,
.v-sidebar-menu .vsm--scroll-wrapper:focus .vsm--scroll-bar,
.v-sidebar-menu .vsm--scroll-wrapper:hover .vsm--scroll-bar {
  opacity: 1;
}
.v-sidebar-menu .vsm--scroll-overflow {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.v-sidebar-menu .vsm--scroll {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  width: calc(100% + 17px);
}
.v-sidebar-menu .vsm--scroll > .vsm--menu {
  position: static !important;
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.v-sidebar-menu .vsm--scroll-bar {
  bottom: 2px;
  opacity: 0;
  position: absolute;
  right: 2px;
  top: 2px;
  width: 6px;
  z-index: 5;
}
.v-sidebar-menu .vsm--scroll-bar,
.v-sidebar-menu .vsm--scroll-thumb {
  border-radius: 4px;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.v-sidebar-menu .vsm--scroll-thumb {
  background-color: #aaa;
  cursor: pointer;
  display: block;
  opacity: 0.5;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-sidebar-menu .vsm--scroll-thumb:hover {
  opacity: 1;
}
.v-sidebar-menu .vsm--dropdown,
.v-sidebar-menu .vsm--menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
.v-sidebar-menu .vsm--dropdown {
  padding: 5px;
}
.v-sidebar-menu .vsm--item {
  display: block;
  position: static !important;
  white-space: nowrap;
  width: 100%;
}
.v-sidebar-menu .vsm--link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 10px 15px;
  position: static !important;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.v-sidebar-menu .vsm--link_active {
  font-weight: 600;
}
.v-sidebar-menu .vsm--link_disabled {
  opacity: 0.4;
  pointer-events: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  -ms-flex-negative: 0;
  border-radius: 3px;
  flex-shrink: 0;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 35px;
}
.v-sidebar-menu .vsm--icon {
  display: inline-block;
  margin-right: 15px;
  z-index: 20;
}
.v-sidebar-menu .vsm--title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-box-flex: 1;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
}
.v-sidebar-menu .vsm--title > span:first-child {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.v-sidebar-menu .vsm--title_hidden {
  clip: rect(0, 0, 0, 0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.v-sidebar-menu .vsm--arrow {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 30px;
  justify-content: center;
  text-align: center;
  width: 30px;
}
.v-sidebar-menu .vsm--arrow_default {
  height: 12px;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  width: 12px;
}
.v-sidebar-menu .vsm--arrow_default:before {
  border-bottom: 2px solid;
  border-left: 2px solid;
  content: "";
  display: inline-block;
  height: 6px;
  left: 0;
  position: absolute;
  top: 2px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 6px;
}
.v-sidebar-menu .vsm--arrow_open .vsm--arrow_default {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.v-sidebar-menu .vsm--header {
  font-size: 14px;
  font-weight: 600;
  padding: 10px 15px;
  text-transform: uppercase;
  white-space: nowrap;
}
.v-sidebar-menu .vsm--badge_default {
  border-radius: 3px;
  font-size: 12px;
  font-weight: 600;
  height: 20px;
  line-height: 20px;
  padding: 0 6px;
  text-transform: uppercase;
}
.v-sidebar-menu .vsm--toggle-btn {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 18px;
  height: 65px;
  justify-content: center;
  text-align: center;
  width: 100%;
}
.v-sidebar-menu .vsm--toggle-btn_default {
  height: 12px;
  position: relative;
  width: 12px;
}
.v-sidebar-menu .vsm--toggle-btn_default:before {
  border-left: 2px solid;
  content: "";
  display: inline-block;
  height: 12px;
  left: 0;
  position: absolute;
  top: 0;
}
.v-sidebar-menu .vsm--toggle-btn_default:after {
  border-bottom: 2px solid;
  border-left: 2px solid;
  content: "";
  display: inline-block;
  height: 6px;
  left: 4px;
  position: absolute;
  top: 2px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 6px;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover,
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1:hover {
  background-color: transparent !important;
}
.v-sidebar-menu.vsm_collapsed .vsm--toggle-btn_default {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.v-sidebar-menu.vsm_collapsed.vsm_rtl .vsm--toggle-btn_default {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.v-sidebar-menu.vsm_collapsed .vsm--scroll-wrapper {
  width: calc(100% + 8px);
}
.v-sidebar-menu.vsm_rtl {
  direction: rtl;
  left: inherit;
  right: 0;
  text-align: right;
}
.v-sidebar-menu.vsm_rtl .vsm--icon {
  margin-left: 15px;
  margin-right: 0;
}
.v-sidebar-menu.vsm_rtl .vsm--arrow_default {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.v-sidebar-menu.vsm_rtl .vsm--arrow_open .vsm--arrow_default {
  -webkit-transform: rotate(-90deg) scaleX(-1);
  transform: rotate(-90deg) scaleX(-1);
}
.v-sidebar-menu.vsm_rtl .vsm--toggle-btn_default {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.v-sidebar-menu.vsm_rtl .vsm--scroll-bar {
  left: 2px;
  right: inherit;
}
.v-sidebar-menu.vsm_relative {
  height: 100%;
  position: relative;
}
.v-sidebar-menu .expand-enter-active,
.v-sidebar-menu .expand-leave-active {
  overflow: hidden;
  -webkit-transition: height 0.3s ease;
  transition: height 0.3s ease;
}
.v-sidebar-menu .expand-enter-from,
.v-sidebar-menu .expand-leave-to {
  height: 0 !important;
}
.v-sidebar-menu .slide-animation-enter-active,
.v-sidebar-menu .slide-animation-leave-active {
  -webkit-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.v-sidebar-menu .slide-animation-enter-from,
.v-sidebar-menu .slide-animation-leave-to {
  width: 0 !important;
}
.v-sidebar-menu {
  background-color: #2a2a2e;
}
.v-sidebar-menu .vsm--link {
  color: #fff;
}
.v-sidebar-menu .vsm--link_level-1.vsm--link_active {
  /* -webkit-box-shadow: inset 3px 0 0 0 #4285f4;
  box-shadow: inset 3px 0 0 0 #4285f4; */
  box-shadow: none;
}
.v-sidebar-menu .vsm--link_level-1 .vsm--icon {
  background-color: #1e1e21;
}
.v-sidebar-menu .vsm--link_hover {
  background-color: rgba(30, 30, 33, 0.5);
}
.v-sidebar-menu .vsm--link_mobile {
  color: #fff;
}
.v-sidebar-menu.vsm_expanded .vsm--link_level-1.vsm--link_open {
  background-color: #4285f4;
  color: #fff;
}
.v-sidebar-menu.vsm_expanded .vsm--link_level-1.vsm--link_open .vsm--icon {
  background-color: #4285f4;
}
.v-sidebar-menu.vsm_collapsed .vsm--link_level-1.vsm--link_hover .vsm--icon {
  background-color: #4285f4;
  color: #fff;
}
.v-sidebar-menu .vsm--dropdown {
  background-color: #36363b;
}
.v-sidebar-menu .vsm--mobile-bg {
  background-color: #4285f4;
}
.v-sidebar-menu.vsm_rtl .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: inset -3px 0 0 0 #4285f4;
  box-shadow: inset -3px 0 0 0 #4285f4;
}
.v-sidebar-menu .vsm--header {
  color: hsla(0, 0%, 100%, 0.7);
}
.v-sidebar-menu .vsm--badge_default,
.v-sidebar-menu .vsm--toggle-btn {
  background-color: #1e1e21;
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme {
  background-color: #2a2a2a;
}
.v-sidebar-menu.vsm_white-theme .vsm--link {
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: inset 3px 0 0 0 #4285f4;
  box-shadow: inset 3px 0 0 0 #4285f4;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1.vsm--link_active .vsm--icon {
  background-color: #bbc5d6;
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_level-1 .vsm--icon {
  background-color: #262626;
}
.v-sidebar-menu.vsm_white-theme .vsm--link_hover {
  background-color: hsla(0, 0%, 95%, 0.5);
}
.v-sidebar-menu.vsm_white-theme .vsm--link_mobile {
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded .vsm--link_level-1.vsm--link_open {
  background-color: #4285f4;
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme.vsm_expanded
  .vsm--link_level-1.vsm--link_open
  .vsm--icon {
  background-color: #4285f4;
}
.v-sidebar-menu.vsm_white-theme.vsm_collapsed
  .vsm--link_level-1.vsm--link_hover
  .vsm--icon {
  background-color: #4285f4;
  color: #fff;
}
.v-sidebar-menu.vsm_white-theme .vsm--dropdown {
  background-color: #e3e3e3;
}
.v-sidebar-menu.vsm_white-theme .vsm--mobile-bg {
  background-color: #4285f4;
}
.v-sidebar-menu.vsm_white-theme.vsm_rtl .vsm--link_level-1.vsm--link_active {
  -webkit-box-shadow: inset -3px 0 0 0 #4285f4;
  box-shadow: inset -3px 0 0 0 #4285f4;
}
.v-sidebar-menu.vsm_white-theme .vsm--header {
  color: rgba(38, 38, 38, 0.7);
}
.v-sidebar-menu.vsm_white-theme .vsm--badge_default,
.v-sidebar-menu.vsm_white-theme .vsm--toggle-btn {
  background-color: #2a2a2a;
  color: #fff;
}

#demo {
  padding-left: 290px;
  transition: 0.3s ease;
}
#demo.collapsed {
  padding-left: 65px;
}
#demo.onmobile {
  padding-left: 65px;
}
.sidebar-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
  z-index: 900;
}
.align-items-center {
  align-items: center;
}
.strong {
  font-weight: 900;
}
.btn-outline-primary {
  color: #fff;
  background-color: #000;
  border-color: #fff;
}
.gray-border {
  border: thin solid #2a2a2a;
}

.align-base {
  align-items: baseline;
}
.align-self-end {
  align-self: self-end;
}
.center-over-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.graphite-gradient {
  background: rgb(17, 17, 17, 0.8);
}
.black {
  background: #000 !important;
}
.border-bottom-light {
  border-bottom: 1px solid rgba(255, 255, 255, 1);
}
.navbar-toggler {
  background: #fff;
}
.loginError {
  border-left: 4px solid #d63638;
  padding: 12px;
  margin-left: 0;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  word-wrap: break-word;
  color: #000;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.v-sidebar-menu {
  z-index: 9999;
}

.amerus-blue,
h2 {
  color: #00adee !important;
}
.amerus-grey {
  color: #59595c !important;
}
.amerus-gold {
  color: #ffe29b;
}

@media only screen and (max-width: 600px) {
  .display-1 {
    font-size: 4em;
  }
  .mt-md-big {
    margin-top: 170px !important;
  }
}

@media only screen and (max-width: 800px) {
  .mt-md-big {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 1200px) {
  /* .hero-bg {
    background: #000;
  } */
  .mt-md-big {
    margin-top: 150px;
  }
}

/* Turn off parallax scrolling for all tablets and phones. Increase/decrease the pixels if needed */
@media only screen and (max-device-width: 1400px) {
  .hero-bg {
    background-attachment: scroll;
  }
  .display-1 {
    font-size: 3em;
  }
}
</style>
