<template>
  <div class="ranking">
    <h1>This is your ranking page!</h1>
    <a @click="$router.go(-1)">Return</a>
  </div>
</template>

<script>
export default { name: "Ranking" };
</script>

