<template>
  <div class="container-fluid">
    <div class="row-fluid">
      <div class="col">
        <div id="content" class="d-flex" style="min-height: 400px">
          <Loading />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../components/Loading";

export default {
  name: "Dashboard",
  components: {
    Loading,
  },
  methods: {},
  computed: {},
  mounted() {
    setTimeout(() => {
      this.$router.push({ path: "/dashboard" });
    }, 6000);
  },
};
</script>
