<template>
  <div class="btn-group-vertical w-100 mr-4 float-left"><slot></slot></div>
</template>

<script>
export default {
  name: "ButtonGroup",
};
</script>

<style scoped>
</style>


