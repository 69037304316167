<template>
  <div id="content" class="demo col pt-0">
    <navbar />
    <Welcome />
    <div class="row-fluid">
      <div class="profile">
        <div class="container-fluid p-0">
          <!-- <h1>This is your profile page, {{ name }}!</h1> -->
          <div class="main-body">
            <!-- Breadcrumb -->
            <!-- <nav aria-label="breadcrumb" class="main-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="index.html">Home</a></li>
            <li class="breadcrumb-item">
              <a href="javascript:void(0)">User</a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              User Profile
            </li>
          </ol>
        </nav> -->
            <!-- /Breadcrumb -->

            <div class="row gutters-sm">
              <div class="col-md-4 mb-3">
                <div class="card">
                  <div class="card-body">
                    <div
                      class="d-flex flex-column align-items-center text-center"
                    >
                      <img
                        :src="profileImage"
                        alt="Admin"
                        class="rounded-circle"
                        width="100"
                      />
                      <div class="mt-3">
                        <h4 class="text-light">{{ name }}</h4>
                        <!-- <p class="text-light mb-1">Full Stack Developer</p> -->
                        <p class="text-light font-size-sm">
                          {{ location }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card mt-3">
                  <ul class="list-group list-group-flush">
                    <!-- <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-globe mr-2 icon-inline"
                        >
                          <circle cx="12" cy="12" r="10"></circle>
                          <line x1="2" y1="12" x2="22" y2="12"></line>
                          <path
                            d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"
                          ></path></svg
                        >Website
                      </h6>
                      <span class="text-light">https://bootdey.com</span>
                    </li> -->
                    <!-- <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-github mr-2 icon-inline"
                        >
                          <path
                            d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"
                          ></path></svg
                        >Github
                      </h6>
                      <span class="text-light">bootdey</span>
                    </li> -->
                    <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="
                            feather feather-twitter
                            mr-2
                            icon-inline
                            text-info
                          "
                        >
                          <path
                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"
                          ></path></svg
                        >Twitter
                      </h6>
                      <span class="text-light"
                        ><a :href="twitter" target="_blank">Twitter</a></span
                      >
                    </li>
                    <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="
                            feather feather-linkedin
                            mr-2
                            icon-inline
                            text-info
                          "
                          fill="currentColor"
                        >
                          <path
                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 8c0 .557-.447 1.008-1 1.008s-1-.45-1-1.008c0-.557.447-1.008 1-1.008s1 .452 1 1.008zm0 2h-2v6h2v-6zm3 0h-2v6h2v-2.861c0-1.722 2.002-1.881 2.002 0v2.861h1.998v-3.359c0-3.284-3.128-3.164-4-1.548v-1.093z"
                          />
                        </svg>

                        LinkedIn
                      </h6>
                      <span class="text-light"
                        ><a :href="linkedIn" target="_blank">LinkedIn</a></span
                      >
                    </li>
                    <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="
                            feather feather-facebook
                            mr-2
                            icon-inline
                            text-primary
                          "
                        >
                          <path
                            d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"
                          ></path></svg
                        >Facebook
                      </h6>
                      <span class="text-light"
                        ><a :href="facebook" target="_blank">Facebook</a></span
                      >
                    </li>
                    <li
                      class="
                        list-group-item
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                      <h6 class="mb-0 text-light">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="
                            feather feather-youtube
                            mr-2
                            icon-inline
                            text-primary
                          "
                        >
                          <path
                            d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
                          ></path></svg
                        >Youtube
                      </h6>
                      <span class="text-light"
                        ><a :href="youtube" target="_blank">Youtube</a></span
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-8">
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Full Name</h6>
                      </div>
                      <div class="col-sm-9 text-light">{{ name }}</div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Email</h6>
                      </div>
                      <div class="col-sm-9 text-light">{{ email }}</div>
                    </div>
                    <hr />
                    <!-- <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Phone</h6>
                      </div>
                      <div class="col-sm-9 text-light">(239) 816-9029</div>
                    </div> 
                    <hr />-->
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Mobile</h6>
                      </div>
                      <div class="col-sm-9 text-light">{{ mobileNumber }}</div>
                    </div>
                    <hr />
                    <!-- <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Start Date</h6>
                      </div>
                      <div class="col-sm-9 text-light">{{ StartDate }}</div>
                    </div>
                    <hr /> -->
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Total Premium</h6>
                      </div>
                      <div class="col-sm-9 text-light">
                        ${{ new Intl.NumberFormat().format(premium) }}
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Sales</h6>
                      </div>
                      <div class="col-sm-9 text-light">
                        <!-- {{ new Intl.NumberFormat().format(sales) }} -->
                        N/A
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Amerus Bucks</h6>
                      </div>
                      <div class="col-sm-9 text-light">{{ amerusBucks }}</div>
                    </div>

                    <hr />
                    <!-- <div class="row">
                      <div class="col-sm-3">
                        <h6 class="mb-0 text-light">Address</h6>
                      </div>
                      <div class="col-sm-9 text-light">
                        Bay Area, San Francisco, CA
                      </div>
                    </div>
                    <hr /> -->

                    <!-- <div class="row">
                      <div class="col-sm-12">
                        <a class="btn btn-info" target="__blank" href="#"
                          >Edit</a
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="card mb-3">
                  <div class="card-body">
                    <div class="row">
                      <div class="col text-light">
                        <h3>Achievements</h3>
                        <img
                          src="../assets/AllStarGold.png"
                          alt="All-Star Badge"
                          class="img-fluid mr-5"
                        />
                        <img
                          src="../assets/DiamondBadgeEmpty-01.png"
                          alt="Diamond Badge Empty"
                          class="img-fluid mr-5"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <div class="row gutters-sm">
                  <div class="col-sm-6 mb-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h6 class="d-flex align-items-center mb-3">
                          <i class="material-icons text-info mr-2">assignment</i
                          >Project Status
                        </h6>
                        <small>Web Design</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 80%"
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Website Markup</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 72%"
                            aria-valuenow="72"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>One Page</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 89%"
                            aria-valuenow="89"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Mobile Template</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 55%"
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Backend API</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 66%"
                            aria-valuenow="66"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6 mb-3">
                    <div class="card h-100">
                      <div class="card-body">
                        <h6 class="d-flex align-items-center mb-3">
                          <i class="material-icons text-info mr-2">assignment</i
                          >Project Status
                        </h6>
                        <small>Web Design</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 80%"
                            aria-valuenow="80"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Website Markup</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 72%"
                            aria-valuenow="72"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>One Page</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 89%"
                            aria-valuenow="89"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Mobile Template</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 55%"
                            aria-valuenow="55"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <small>Backend API</small>
                        <div class="progress mb-3" style="height: 5px">
                          <div
                            class="progress-bar bg-primary"
                            role="progressbar"
                            style="width: 66%"
                            aria-valuenow="66"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a @click="$router.go(-1)" class="btn btn-primary mt-4"
      ><i class="fas fa-long-arrow-alt-left"></i> Go Back</a
    >
  </div>
</template>

<script>
import Navbar from "../components/Navbar.vue";
import Welcome from "../components/Welcome";

export default {
  name: "Profile",
  components: { Navbar, Welcome },
  computed: {
    name() {
      return this.$store.getters.getName;
    },
    profileImage() {
      return this.$store.getters.getProfileImage;
    },
    email() {
      return this.$store.getters.getEmail;
    },
    mobileNumber() {
      return this.$store.getters.getMobileNumber;
    },
    facebook() {
      return this.$store.getters.getFacebook;
    },
    twitter() {
      return this.$store.getters.getTwitter;
    },
    linkedIn() {
      return this.$store.getters.getLinkedIn;
    },
    youtube() {
      return this.$store.getters.getYouTube;
    },
    location() {
      return this.$store.getters.getLocation;
    },
    StartDate() {
      return this.$store.getters.getAgentStartDate;
    },
    revenue() {
      return this.$store.getters.getRevenueSinceHire;
    },
    sales() {
      return this.$store.getters.getSalesSinceHire;
    },
    premium() {
      return this.$store.getters.getPremium;
    },
    amerusBucks() {
      return this.$store.getters.getAmerusBucks;
    },
  },
};
</script>

<style scoped>
hr {
  border-top: 1px solid #fff;
}
.fa-long-arrow-alt-left,
.btn-primary {
  color: #fff !important;
}
.card {
  background-color: #2a2a2a;
}
.card-title {
  color: #afafb4;
}
.list-group-item {
  background-color: #2a2a2a;
}
</style>